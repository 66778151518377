.contactFullPage {
  width: 100%;
  height: fit-content;
  min-height: 90vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: transparent;
  background: url("/static/media/lines-bg.98e30175.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 86px;
  padding-bottom: 0;
}
.containerContact {
  width: 100%;
  max-width: 1446px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}
.mapContainer{
  width: 100%;
  height: 600px;
  margin-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
.formContactPageDiv {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.fullContactInput {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  height: 40px;
  padding: 0;
  font-size: 18px;
  margin-bottom: 10px;
  color: #2D2D40;
  margin-bottom: 20px;
  font-family: ligh-font;
  background: transparent;
}
.fullContactButton {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  width: 143px;
  height: 50px;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  color: #000347;
  border: 1px solid #000347;
}
.contactFullPageCloseBtn {
  padding: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  background-color: #1ca8fc !important;
  color: white;
  border-radius: 14px;
  cursor: pointer;
  font-size: 17px;
}
.mainContentContainer {
  display: flex;
  flex: 10;
  height: fit-content;
  padding-left: 15px;
  margin-bottom: 50px;
}
.h1FullPageContact {
  font-size: 35px;
  color: #000347;
  font-weight: bold;
  font-family: black-font;
  text-transform: uppercase;
  height: fit-content;
  margin-bottom: 30px;
}
.footerFrom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copyForm {
  color: #8d8fba;
  font-size: 16px;
}
.h2FullPageContact {
  font-size: 20px;
  color: #000347;
  font-weight: bold;
  font-family: black-font;
  text-transform: uppercase;
}
.contactFullPageLeftText {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.twoinputsDiv {
  width: 100%;
  display: flex;
}
.firstInput {
  margin-right: 10px;
}
.twoinputsDiv input {
  width: 100%;
}
.contactFullPageLeft {
  display: flex;
  flex-direction: column;
  flex: 6;
  height: 100%;
  padding-right: 5%;
  padding-top: 7%;
}
.contactFullPageLeftForm {
  display: flex;
  flex-direction: column;
}
.contactFullPageRight {
  padding-left: 5%;
  flex: 4;
  width: 100%;
  height: auto;
  margin-top: 7%;
}

.contactFullPageRightPart1 {
  display: flex;
  flex-direction: column;
  flex: 5;
  height: fit-content;
  min-width: 200px;
  justify-content: center;
}
.getLocationContact {
  text-decoration: none;
  color: #1ca8fc;
  font-size: 17px;
  font-family: ligh-font !important;
  border: 1px solid #464884;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
}
.contactFullPageRightPart2 {
  display: flex;
  flex-direction: column;
  flex: 5;
  height: fit-content;
  justify-content: center;
  min-width: 200px;
}
.locations {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap: wrap;
}
.pFullContact {
  font-size: 18px;
  color: #000347;
  font-family: ligh-font;
}
.SocialIconContact {
  font-size: 30px !important;
  color: #000347;
}
@media (max-width: 1200px) {
  .containerContact {
    max-width: 940px;
  }
}
@media (max-width: 991px) {
  .containerContact {
    max-width: 720px;
    
  }
  .contactFullPage{
    overflow: auto;

  }
  .mainContentContainer {
    height: auto;
    flex-direction: column;
    overflow-y: scroll !important;
  }
  .mainContentContainer {
    display: flex;
    flex: 10;
    margin-top: 0px;
  }
  .contactFullPageRight {
    padding-left: 0;
    padding-right: 5%;
    margin-bottom: 50px;
    margin-top: 100px;
  }
  .fullContactInput {
    margin-right: 0px;
  }
  .contactFullPageLeft {
    border-right: none;
  }

  .contactFullPageLeftText {
    padding-top: 20px;
  }
}

@media (max-width: 700px) {
  .rightSquaresHome {
    padding-right: 5%;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
  .aa11 {
    margin-left: 5%;
  }
}
@media (max-width: 400px) {
  .twoinputsDiv {
    flex-direction: column;
  }
  .footerFrom {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .fullContactButton {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

::-webkit-input-placeholder {
  color: #919191;
}
