.cards5Div {
  width: auto;
  height: 620px;
  position: relative;
  margin-bottom: 80px;
  margin-top: 30px;
}

.cards5DivCard {
  position: absolute;
  width: 32%;
  height: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
  border: 1px solid #1ca8fc;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  z-index: 9;
}

.cards5DivCard.div1 {
  top: 0;
  left: 0;
}
.cards5DivCard.div2 {
  top: 0;
  right: 33.5%;
  transform: translateX(-0%);
}
.cards5DivCard.div3 {
  top: 0;
  right: 0;
}
.cards5DivCard.div4 {
  bottom: 0px;
  left: 0;
}
.cards5DivCard.div5 {
  bottom: 0px;
  right: 33.5%;
  transform: translateX(-0%);
}
.cards5DivCard.div6 {
  bottom: 0px;
  right: 0;
}
.animation {
  z-index: 10;
}

.cards5DivCard.animation {
  width: 66.5%;
  height: 100%;
  animation-name: becomeBig;
  animation-duration: 0.5s;
}

.cards5DivCard.false {
  animation-name: becomeSmall;
  animation-duration: 0.5s;
}

.cards5DivCard:hover {
  box-shadow: 4px 2px 5px 5px rgb(241, 241, 241);
}

@keyframes becomeBig {
  from {
    z-index: 10;
    width: 32%;
    height: 49%;
  }
  to {
    width: 66.5%;
    height: 100%;
    z-index: 10;
  }
}

@keyframes becomeSmall {
  0% {
    z-index: 10;
  }
  99% {
    z-index: 10;
  }
  100% {
    z-index: 9;
  }
}

.card5Img {
  width: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeBigCard {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  z-index: 1011;
}
.card5Square {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 20px;
}

p {
  font-size: 20px;
  line-height: 1.4;
}
.divDescriptionCards {
  color: #2d2d40;
  font-size: 18px;
  font-family: ligh-font;
  margin-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
  /* max-height: 53px; */
  overflow: hidden;
  max-height: 50px;
}
.divDescriptionCards.animation {
  text-align: left;
}
.div5Title {
  font-size: 20px;
  color: #000347;
  font-weight: bolder;
}
@media (max-width: 500px) {
  .cards5DivCard.animation {
    width: 100% !important;
    height: 66.5%;
    animation-name: becomeBigMobileSmall2;
    animation-duration: 0.5s;
    z-index: 11;
  }
}
@media (max-width: 600px) {
  .cards5DivCard.animation {
    width: 98%;
    height: 66.5%;
    animation-name: becomeBigMobileSmall;
    animation-duration: 0.5s;
    z-index: 11;
  }
}
@media (max-width: 850px) {
  .cards5DivCard:hover {
    box-shadow: none;
  }
  .divDescriptionCards {
    font-size: 16px;
  }
  .div5Title {
    font-size: 18px;
  }
  .cards5Div {
    height: 920px;
  }
  .cards5DivCard {
    position: absolute;
    width: 48%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    background-color: white;
    cursor: pointer;
    border: 1px solid #1ca8fc;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9;
    overflow: hidden;
  }

  .cards5DivCard.div1 {
    top: 0;
    left: 0;
  }
  .cards5DivCard.div2 {
    top: 0;
    right: 0;
    transform: translateX(-0%);
  }
  .cards5DivCard.div3 {
    top: 33.5%;
    left: 0 !important;
  }
  .cards5DivCard.div4 {
    top: 33.5%;
    right: 0 !important;
    left: auto;
  }
  .cards5DivCard.div5 {
    bottom: 0px;
    left: 0 !important;
    transform: translateX(-0%);
  }
  .cards5DivCard.div6 {
    bottom: 0px;
    right: 0 !important;
  }
  .cards5DivCard.animation {
    width: 98%;
    height: 66.5%;
    animation-name: becomeBigMobile;
    animation-duration: 0.5s;
    z-index: 11;
  }

  .cards5DivCard.false {
    animation-name: becomeSmall;
    animation-duration: 0.5s;
  }
  .divDescriptionCards.animation {
    max-height: fit-content;
    overflow: visible;
    animation-name: becomeBigMobileText;
    animation-duration: 1s;
    text-align: left;
  }
}

@keyframes becomeBigMobileText {
  from {
    /* max-height: 53px; */
    overflow: hidden;
    max-height: 50px;
  }
  to {
    max-height: fit-content;
    overflow: visible;
    max-height: fit-content;
  }
}

@keyframes becomeBigMobile {
  from {
    z-index: 11;
    width: 49%;
    height: 300px;
  }
  to {
    width: 98%;
    height: 66.5%;
    z-index: 11;
  }
}

@keyframes becomeBigMobileSmall {
  from {
    z-index: 11;
    width: 49%;
    height: 300px;
  }
  to {
    width: 98%;
    height: 66.5%;
    z-index: 11;
  }
}

@keyframes becomeBigMobileSmall2 {
  from {
    z-index: 11;
    width: 49%;
    height: 300px;
  }
  to {
    width: 100%;
    height: 66.5%;
    z-index: 11;
  }
}
