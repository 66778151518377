.nav-menu {
  position: absolute;
  width: 100%;
  height: 86px;
  /* padding-bottom: 2px; */
  z-index: 100;
  transition: 0.3s linear all;
  animation: fadeIn cubic-bezier(0.23, 1, 0.32, 1) 1;
  display: block;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.1s;
}

.up:hover,
.up:focus {
  box-shadow: inset 0 -4rem 0 0 var(--hover);
}

.up {
  --hover: #1ca7fc;
}

.hoverUp {
  transition: 0.35s ease-in-out;
}

.hoverUp:hover,
.hoverUp:focus {
  border-color: var(--hover);
  color: #fff !important;
  border: 1px solid transparent !important;
}

.hoverFullInsideColor {
  transition: all 0.4s ease-in-out;
}

.hoverFullInsideColor:hover {
  background-color: #1392e0 !important;
}

.nav-menu.hide {
  display: none;
}

.reactIconHover {
  color: #000347;
}

.reactIconHoverDiv:hover .reactIconHover {
  color: #1ca7fc;
}

.nav-menu.hide.show-sticky {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.white-menu .nav-menu {
  position: fixed;
  z-index: 15;
  background-color: transparent !important;
  border-bottom: 1px solid #dedede !important;
  /* -webkit-box-shadow: 0px 6px 31px 3px rgba(151,151,151,1);
    -moz-box-shadow: 0px 6px 31px 3px rgba(151,151,151,1);
    box-shadow: 0px 6px 31px 3px rgba(151,151,151,1); */
}

.nav-menu.active {
  position: fixed;
  background-color: #fff !important;
  border-bottom: 1px solid #dedede !important;
  /* -webkit-box-shadow: 0px 6px 31px 3px rgba(151,151,151,1);
    -moz-box-shadow: 0px 6px 31px 3px rgba(151,151,151,1);
    box-shadow: 0px 6px 31px 3px rgba(151,151,151,1); */
}

.nav-menu.scrollActive {
  display: block;
  background-color: #fff;
  transition: 0.3s linear all;
}

.nav-menu.show-sticky {
  border: none !important;
  background-color: transparent;
}

.nav-menu.show-sticky .nav-menu_ {
  position: relative;
}

.nav-menu.active .nav-menu_ .logo img {
  content: url(./../img/logo-color.svg);
}

.nav-menu.active .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ {
  opacity: 0;
}

.nav-menu .nav-menu_ {
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  z-index: 300;
}

.nav-menu .nav-menu_ .logo {
  position: relative;
  float: left;
  width: 180px;
  height: 100%;
  cursor: pointer;
}

.nav-menu .nav-menu_ .logo img {
  position: relative;
  width: 100%;
  height: 84px;
  margin-top: 20px;
}

.white-menu .nav-menu .nav-menu_ .logo img {
  content: url(./../img/logo-color.svg);
}

.nav-menu .nav-menu_ .nav-menu__ {
  position: relative;
  float: right;
  width: calc(100% - 180px);
  height: 80%;
  display: flex;
  justify-content: flex-end;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside {
  position: relative;
  float: left;
  width: auto;
  height: 100%;
  margin: 0px 20px;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside.extra {
  width: 40px;
  margin-right: 0px;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside.extra .helper-div {
  display: none;
}

.nav-menu.active .nav-menu_ .nav-menu__ .nav-menu__inside a,
.nav-menu.active .nav-menu_ .nav-menu__ .nav-menu__inside span,
.white-menu .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside a,
.white-menu .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside span,
.white-menu .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .a {
  color: #000047;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside a,
.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside span,
.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .a {
  position: relative;
  float: left;
  font-size: 18px;
  z-index: 10;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
}
.nav-menu__inside .ab {
  align-items: flex-start !important;
}

.white-menu .nav-menu .nav-menu_ .showButton {
  content: url('./../img/menuuClosedColor.svg');
}

.nav-menu.active .showButton {
  content: url('./../img/menuuClosedColor.svg');
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div {
  position: absolute;
  bottom: -8px;
  width: 0;
  height: 4px;
  background-color: #00a9fc;
  z-index: 9;
  transition: 0.3s linear all;
  left: 0px;
  z-index: -1;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside:hover .helper-div,
.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside.active .helper-div {
  width: 21px;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .mail-img {
  position: absolute;
  bottom: -10px;
  width: 40px;
  height: 40px;
  background-color: #00a9fc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .mail-img img {
  width: 15px;
  height: 15px;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ {
  position: absolute;
  width: 100%;
  height: auto;
  top: 100%;
  transition: 0.3s linear all;
}

.social-nav,
.logo-mobile {
  display: none;
}

.social-nav a:first-child {
  border-right: none;
}

.social-nav a:last-of-type {
  border-left: none;
}

.social-nav a {
  border: 2px solid #464884;
  position: relative;
  float: left;
  font-size: 18px;
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-nav a img {
  width: 20px;
  height: 20px;
}

.scroll-arrow {
  position: absolute;
  right: 0;
  bottom: 40px;
  cursor: pointer;
}

.language-box {
  cursor: pointer;
  position: relative;
  float: left;
  font-size: 18px;
  z-index: 10;
  color: #fff;
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside span.language {
  border: 1px solid #464884;
  padding: 2px 6px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language.active {
  background-color: #00a9fc;
  border: 1px solid transparent;
}

/* .nav-menu_  .language-box {
    position: absolute;
    bottom: 40px;
    right: 20px;
} */
.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ img {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  margin: 20px 0px;
  animation: jump 2s infinite;
  transition: 0.3s ease-in-out;
}

.white-menu
  .nav-menu
  .nav-menu_
  .nav-menu__
  .nav-menu__inside
  .helper-div_
  img {
  content: url(./../img/arrowUpColor.svg);
}

@keyframes jump {
  0% {
    top: 0;
  }

  50% {
    top: 10px;
  }

  100% {
    top: 0;
  }
}

.nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ span {
  writing-mode: vertical-rl;
  height: auto;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.showButton {
  display: none;
}

.bgImage {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/Bg.svg');
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: bottom; */
}

.first-section {
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
}

.home-page {
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
}

.home-page .home-page_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.home-page_text.break-point .desktop-text {
  display: block;
}

.home-page_text.break-point .mobile-text {
  display: none;
}

.home-page .home-page_text span {
  font-size: 45px;
  position: relative;
  color: #fff;
}

.home-page .home-page_text span .emphasise {
  font-family: 'black-font';
  text-transform: uppercase;
}

.home-page .home-page_text div .banner-btn {
  margin-top: 45px;
  height: 45px;
  color: #000347;
  background: #fff;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  padding: 12px 35px;
  display: inline-block;
  transform: skew(-21deg);
  border-left: 20px solid #1ca7fc;
  border-right: 20px solid #1ca7fc;
}

.home-page .home-page_text div .banner-btn span {
  font-size: 18px;
  color: #000347;
  display: inline-block;
  transform: skew(21deg);
}

.tf_button {
  position: relative;
  float: left;
  width: auto;
  padding: 0px 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-size: 16px;
  margin-top: 40px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.tf_button.maxWidth {
  max-width: 150px;
}

.tf_button.extra {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.tf_button.primary {
  color: #fff;
}

.tf_button.negativ {
  color: #000047;
}

.remove_margin {
  margin: 0 !important;
}

.tf_button.active .helper-div,
.tf_button:hover .helper-div {
  height: 100%;
}

.tf_button .helper-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background-color: #00a9ff;
  z-index: -1;
  transition: 0.3s linear all;
}

.home-page .social-home {
  position: absolute;
  bottom: 40px;
}

.home-page .social-home a:first-child {
  border-right: none;
}

.home-page .social-home a:last-of-type {
  border-left: none;
}

.home-page .social-home a {
  border: 2px solid #c3c4e8;
  position: relative;
  float: left;
  font-size: 18px;
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page .social-home a:hover {
  color: #1ca7fc !important;
}

.home-page .social-home a img {
  width: 20px;
  height: 20px;
}

.home-page .scroll-arrow {
  position: absolute;
  right: 0;
  bottom: 40px;
  cursor: pointer;
}

.second-section {
  position: relative;
  float: left;
  width: 100%;
  min-height: 500px;
  height: auto;
  padding-top: 50px;
}

.second-section .second-section_title.testimonials {
  /* margin-top: 100px; */
}

.second-section .second-section_title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.second-section .second-section_title.background-add {
  background-color: #f0f4f8;
}

.second-section .second-section_title img {
  position: relative;
  float: left;
  width: 50px;
  height: auto;
  margin-right: 20px;
}

.second-section .second-section_title span {
  position: relative;
  float: left;
  width: auto;
  height: auto;
  color: #000047;
  font-family: black-font;
  text-transform: uppercase;
}

.second-section .second-section_title .btn-wrapper {
  margin-left: auto;
  height: 50px;
}

.second-section .second-section_title .btn-wrapper a {
  border: 1px solid #707070;
  color: #000347;
  font-size: 18px;
  padding: 13px 45px;
  text-decoration: none;
}

.btn-wrapper-boxe {
  display: none;
}

.second-section.client .second-section-content {
  margin-bottom: 0;
}

.aboutUsNew {
  background-color: white;
  width: 100%;
  height: 100%;
  margin-top: 86px;
  padding-top: 10px;
  display: flex;
  flex: 10;
  box-sizing: border-box;
  padding-top: 0;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .aboutUsNew {
    justify-content: flex-start;
  }
}

.aboutusContainer {
  width: 1446px;
  display: flex;
  height: 100%;
  min-height: 700px;
}

.text2company {
  margin-top: 150px;
  padding-left: 15px;
}

.aboutUsNewLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 5;
  font-family: black-font;
  font-size: 30px;
  font-weight: normal;
  justify-content: center;
  margin-top: 50px;
}

.aboutUsNewLeftContainer {
  box-sizing: border-box;
  height: 100%;
  max-width: 700px;
  padding: 50px 50px 50px 0;
  padding-right: 10%;
  padding-top: 100px;
  margin-top: 50px;
  text-align: left;
}

.aboutUsNewRight {
  width: 55%;
  height: 100%;
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 0;
  float: left;
  position: absolute;
  right: 0;
  top: 0;
}

.backcolorabout {
  width: 100%;
  height: fit-content;
  float: left;
  background-color: #f0f4f8;
}

.agileDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .marginBSmaller {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 600px) {
  .inside-career-img {
    margin-top: 50px;
  }

  .aboutUsNewLeftContainerText {
    padding-left: 0px !important;
    margin-top: 20px;
  }

  .text2company {
    padding-left: 0px !important;
  }

  .aboutUsNewRight {
    height: 280px !important;
  }
}

@media (max-width: 1480px) {
  .aboutusContainer {
    width: 1200px;
  }
}

@media (max-width: 1200px) {
  .aboutUsNewLeftContainerText {
    position: relative !important;
    max-width: 100% !important;
    width: 100%;
    text-align: left;
  }

  .text2company {
    margin-top: 0;
  }

  .companyImageLayer {
    display: none;
  }

  .aboutusContainer {
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    float: left;
  }

  .aboutUsNewLeftContainer {
    box-sizing: border-box;
    height: fit-content;
    max-width: 1200px;
    width: 100%;
    padding-left: 5%;
    padding-left: 5%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  #companyPresentationBtn {
    /* display: none !important; */
  }

  .agileDiv {
    padding-left: 5%;
    padding-right: 0%;
  }

  .aboutUsNewRight {
    width: 100%;
    height: 100%;
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 0;
    position: relative;
    bottom: 0;
    left: 0;
  }

  .aboutUsNewLeft {
    margin-top: 0px;
    padding-left: 0;
    height: fit-content;
  }
}

.rightaboutimage {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.aboutUsNewLeftContainerText {
  position: absolute;
  max-width: 750px;
  padding-left: 15px;
}

.deText {
  margin-top: -70px;
}

.companyImageLayer {
  position: absolute;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, white, transparent);
  left: 0px;
}

.second-section .second-section-content {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.second-section .second-section-content .second-section-content-img {
  width: 465px;
  height: 350px;
  object-fit: cover;
}

.second-section .second-section-content span {
  margin: 30px 0px;
  position: relative;
  float: left;
  color: #2d2d40;
  font-size: 20px;
  line-height: 24px;
}

.second-section .second-section-content p {
  position: relative;
  float: left;
  color: #2d2d40;
  font-size: 20px;
  line-height: 35px;
  margin: 30px 0px;
  font-family: ligh-font;
}

.second-section .second-section-content span.sub_title,
.second-section .second-section-content p.sub_title {
  margin-left: 70px;
  line-height: 30px;
}

.second-section .second-section-content .bottom-line {
  position: relative;
  float: left;
  width: 100%;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 0px;
}

.second-section .second-section-content ._intro {
  padding-top: 70px;
}

.second-section_boxes {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.second-section_boxes .boxes {
  padding: 15px;
}

.second-section_boxes .boxes .inside_boxes {
  position: relative;
  float: left;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
}

.second-section_boxes .boxes .inside_boxes img {
  margin-bottom: 30px;
}

.second-section_boxes .boxes .inside_boxes span {
  color: #000047;
  font-size: 20px;
  width: 100%;
  text-align: center;
  background-color: #fff;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -200px;
  border-radius: 4px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 3, 71, 0.05);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 3, 71, 0.05);
  box-shadow: 10px 10px 5px 0px rgba(0, 3, 71, 0.05);
}

.second-section.home_section_introduction {
  background: url('../../assets/img/bckg_1.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.second-section.services {
  background-color: #f0f4f8;
}

.underbannerHomeConatiner {
  float: left;
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 770px;
  display: flex;
}

.blueSquareLeft {
  background-color: #00bdfc;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
}

.underbannerHomeImage {
  position: absolute;
  left: 5%;
  top: 80px;
  width: 500px;
  height: 600px;
  object-fit: cover;
}

.underBannerLayer {
  position: absolute;
  left: 5%;
  top: 80px;
  width: 500px;
  height: 240px;
  background: linear-gradient(to bottom, white, transparent);
  z-index: 5;
}

.blueSquareRight {
  background-color: #00bdfc;
  height: 200px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
}

.underHomeBannerDiv {
  position: absolute;
  float: left;
  padding-top: 50px;
  width: 900px;
  height: 770px;
  left: 650px;
}

.underHomeBannerDivText {
  position: absolute;
  font-size: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 20;
  left: 500px;
  top: 150px;
}

.underHomeBannerDivText span {
  font-family: black-font !important;
  color: #000347;
}

.underHomeBannerDivMobile {
  margin-top: 40px;
  display: none;
}

.beforeAfterImg {
  object-fit: cover !important;
  height: 500px;
}

@media (max-width: 700px) {
  .beforeAfterImg {
    height: 400px !important;
  }
}
@media (max-width: 500px) {
  .beforeAfterImg {
    height: 300px !important;
  }
}
@media (max-width: 400px) {
  .beforeAfterImg {
    height: 250px !important;
  }
}

.underHomeBannerDivTextMobile {
  display: none;
}

.underHomeBannerDivTextPMobile {
  display: none;
}

@media (max-width: 1120px) {
  .underHomeBannerDivMobile {
    display: block;
  }

  .underHomeBannerDivTextMobile {
    display: block;
    font-size: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: black-font;
  }

  .underHomeBannerDivTextPMobile {
    display: block;
  }

  .second-section {
    padding-top: 40px;
  }
}

@media (max-width: 500px) {
  .underbannerHomeConatiner {
    margin-bottom: 40px;
  }

  .underHomeBannerDivTextMobile {
    font-size: 20px;
  }

  .cards5DivCard {
    margin-right: 0px;
  }

  .cards5DivCard.animation {
    margin-right: 0px;
  }
}

@media (max-width: 1120px) {
  .underHomeBannerDiv {
    display: none;
  }

  .underbannerHomeImage {
    display: none;
  }

  .underBannerLayer {
    display: none;
  }

  .underHomeBannerDivText {
    display: none;
  }

  .blueSquareRight {
    display: none;
  }

  .blueSquareLeft {
    display: none;
  }

  .underbannerHomeConatiner {
    min-height: fit-content;
  }
}

@media (max-width: 1450px) {
  .underHomeBannerDiv {
    left: 450px;
  }

  .underbannerHomeImage {
    left: 2%;
    top: 120px;
    width: 400px;
    height: 500px;
  }

  .underHomeBannerDivText {
    left: 320px;
  }

  .underBannerLayer {
    left: 2%;
    top: 120px;
    width: 400px;
  }
}

@media (max-width: 1250px) {
  .underHomeBannerDiv {
    width: 650px;
  }
}

.video_box {
  padding: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-top: 30px;
}

.keyMembersTitle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.underHomeBannerDivTextP {
  width: 100%;
  height: 100%;
  /* padding-left: 500px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.underHomeBannerP {
  color: #2d2d40;
  line-height: 35px;
  font-size: 18px;
  max-width: 800px;
  margin-top: 20px;
  font-family: ligh-font;
}

.video_box video {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.video_box .playpause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border: 1px solid #c8c8c8;
}

.projects_section {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.project_box {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.project_box .left_part {
  position: relative;
  float: left;
  width: 50%;
  background-color: #000047;
  height: 570px;
  padding-left: 40px;
  padding-top: 40px;
}

.project_box .right_part {
  position: relative;
  float: left;
  width: 50%;
  height: 600px;
  background-color: #979797;
}

.project_box .little-boxes {
  display: none;
}

.project_box .left_part .project_title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 0px;
  margin-bottom: 30px;
}

.project_box .left_part .project_title span:first-child {
  font-size: 20px;
}

.project_box .left_part .project_title span {
  position: relative;
  float: left;
  margin: 0px;
  font-size: 20px;
  line-height: 36px;
  color: #fff;
  cursor: pointer;
  font-family: bold-font;
}

.project_box .left_part .project_title span.project_title_ {
  margin-left: 15px;
}

.project_box .left_part .project_title:hover span.project_title_ .div-helper,
.project_box .left_part .project_title.active span.project_title_ .div-helper {
  width: calc(100% + 10px);
}

.project_box .left_part .project_title span.project_title_ .div-helper {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 3px;
  background-color: #00a9ff;
  transition: 0.3s linear all;
}

.project_box .right_part .project_images {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.project_box .right_part .project_images span {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 30px;
  left: 30px;
  margin: 0px;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
}

.project_box .right_part .project_images img {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
}

/** clients **/
.client_box {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #f0f4f8;
  padding: 10px;
  margin-bottom: 100px;
}

.clients_section .client_box {
  margin-bottom: 0;
}

.client_box .left-part {
  background-color: #fff;
  position: relative;
  float: left;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.client_box .left-part .line {
  position: absolute;
  right: 100px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #f3f3f3;
}

.client_box .left-part .left-part_ {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 40px 25px;
  /* justify-content: center; */
}

.client_box .left-part .left-part_ span {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 0;
}

.client_box .left-part .left-part_ .number {
  font-size: 60px;
  line-height: 60px;
  color: #000047;
  font-family: 'black-font';
}

.client_box .left-part .left-part_ .text {
  font-size: 22px;
  line-height: 30px;
  color: #54556e;
}

.client_box .right-part .boxes span {
  margin: 0;
}

.client_box .right-part .boxes {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 10px 0px;
}

.client_box .right-part .boxes .img-box {
  position: relative;
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.client_box .right-part .boxes .img-box img {
  width: 100%;
  height: 100%;
}

.client_box .right-part .box-content {
  position: relative;
  float: left;
  width: calc(100% - 100px);
  height: 100%;
}

.client_box .right-part .box-content .username {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.client_box .right-part .box-content .username span {
  color: #000047;
  font-size: 20px;
  line-height: 30px;
}

.client_box .right-part .box-content .username span img {
  width: auto;
  display: inline;
}

.client_box .right-part .box-content .description {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.client_box .right-part .box-content .description span {
  color: #979797;
  font-size: 14px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client_box .right-part {
  position: relative;
  float: left;
  width: 85%;
  /* height: 400px; */
}

.client_box .right-part .up-arrow,
.client_box .right-part .down-arrow {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
}

.client_box .right-part .up-arrow .inside-arrow {
  left: 0;
  top: 0;
}

.client_box .right-part .down-arrow .inside-arrow {
  left: 0;
  bottom: 0;
}

.client_box .right-part .up-arrow .inside-arrow,
.client_box .right-part .down-arrow .inside-arrow {
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid #00a9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.client_box .right-part .up-arrow .inside-arrow:hover,
.client_box .right-part .up-arrow .inside-arrow.active,
.client_box .right-part .down-arrow .inside-arrow:hover,
.client_box .right-part .down-arrow .inside-arrow.active {
  background-color: #00a9ff;
}

.client_box .right-part .up-arrow .inside-arrow:hover img,
.client_box .right-part .up-arrow .inside-arrow.active img {
  content: url('../img/whiteArrow.svg');
}

.client_box .right-part .down-arrow .inside-arrow:hover img,
.client_box .right-part .down-arrow .inside-arrow.active img {
  content: url('../img/whiteArrowDown.svg');
}

.client_box .right-part .up-arrow .inside-arrow img,
.client_box .right-part .down-arrow .inside-arrow img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.second-section .second-section-content .client_box .bottom-line {
  margin-top: 50px;
}

/** clients **/
/** new clients home **/
.owl-theme.owl-carousel {
  position: relative;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 40%;
}

.owl-carousel .owl-nav button.owl-prev {
  transition: 0.3s linear all;
  left: 20px;
}

.owl-carousel .owl-nav button.owl-next {
  right: 20px;
  transition: 0.3s linear all;
}

.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
  color: #1ca8fc;
  font-size: 35px;
  font-weight: bold;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background: transparent;
}

.owl-carousel .owl-nav button.owl-prev:hover span,
.owl-carousel .owl-nav button.owl-next:hover span {
  color: #54556e;
  transition: 0.3s linear all;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item .testimonial-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 30px 0 30px;
  text-align: center;
}

.item .testimonial-item img {
  height: 150px !important;
  width: 150px !important;
  border-radius: 5px;
}

.item .testimonial-item p {
  font-size: 20px;
  line-height: 35px;
  color: #2d2d40;
  margin: 30px 30px;
  max-width: 530px;
}

.item .testimonial-item .testimonial_nr {
  position: relative;
}

.item .testimonial-item .testimonial_nr p {
  font-family: 'Roboto';
  color: #000347;
  font-weight: 600;
  padding-bottom: 10px;
  margin: 30px 0;
}

.item .testimonial-item .testimonial_nr div {
  position: absolute;
  width: 35px;
  border-top: 1px solid #d0d0d0;
}

.item .testimonial-item .testimonial_nr div:first-of-type {
  top: 47px;
  left: -50px;
}

.item .testimonial-item .testimonial_nr div:last-child {
  top: 47px;
  right: -50px;
}

.right-part .quotes-right,
.right-part .quotes-left {
  position: absolute;
}

.right-part .quotes-right {
  right: 0;
}

.right-part .quotes-left {
  left: 10px;
  bottom: -60px;
}

/** new clients home **/

.partners {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 50px;
}

.partners .partner-box img {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.footer {
  position: relative;
  float: left;
  width: 100%;
  min-height: 500px;
  height: auto;
  background-color: #000347;
  background-image: url('../../assets/img/map.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
}

.footer-logo {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
}

.footer-logo img {
  position: relative;
  float: left;
  width: auto;
  height: 100%;
}

.footer-logo .side-lines {
  position: absolute;
  right: 0;
  top: -100px;
  object-fit: contain;
  height: 50px;
  width: 40px;
}

.footer .footer-content {
  padding-top: 50px;
}

.footer .footer-content_.first-part {
  position: relative;
  float: left;
  height: auto;
}

.footer .footer-content .footer-content_ span.title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  color: #fff;
  font-size: 25px;
  line-height: 35px;
  font-family: black-font;
  font-weight: bold;
  margin-bottom: 47px;
}

.footer .footer-content .footer-content_.first-part a {
  position: relative;
  text-transform: uppercase;
  float: left;
  width: 100%;
  height: auto;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 25px;
}

.footer .footer-content .second-part {
  position: relative;
  float: left;
  height: auto;
}

.footer .footer-content .second-part .footer-content_ {
  position: relative;
  float: left;
}

.footer .footer-content .second-part .footer-content_.blog {
  width: 50%;
}

.footer .footer-content .second-part .footer-content_.contact {
  width: 100%;
}

.footer .footer-content .second-part .footer-content_.contact .contact-form {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .username,
.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .email {
  position: relative;
  float: left;
  width: 100%;
  height: 60px;
  margin-bottom: 47px;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  input::placeholder,
.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  textarea::placeholder {
  color: #8d8fba;
  font-size: 14px;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  input,
.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .username
  .name,
.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .username
  .last_name,
.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  textarea {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  padding-left: 10px;
  color: #fff;
  font-family: regular-font;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  textarea,
.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  input {
  border-bottom: 1px solid #464884;
  padding-bottom: 16px;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .username
  .name {
  width: calc(50% - 10px);
  margin-right: 10px;
  background-color: transparent;
  padding: 0px;
  height: auto;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .username
  .last_name {
  width: calc(50% - 10px);
  margin-left: 10px;
  background-color: transparent;
  padding: 0px;
  height: auto;
}

.error-message {
  color: brown;
  padding: 5px 0px;
  font-size: 13px;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  textarea {
  resize: none;
  height: 100px;
  padding-top: 10px;
  margin-bottom: 37px;
}

.footer .footer-content .footer-content_ .copyright {
  position: relative;
  float: left;
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.footer .footer-content .footer-content_ .copyright span {
  color: #8d8fba;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .social.button {
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .social.button
  .social_ {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.footerSocialIcon {
  font-size: 20px;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .social.button
  .social_
  a {
  color: #fff;
  padding: 10px;
  border: 1px solid #464884;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .social.button
  .social_
  a:hover {
  color: #1ca7fc !important;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .social.button
  .social_
  a
  img {
  width: 20px;
  height: 20px;
}

.footer
  .footer-content
  .second-part
  .footer-content_.contact
  .contact-form
  .submission-btn {
  height: 40px;
  width: 125px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #c3c4e8;
  cursor: pointer;
}

.mobileShow {
  display: none;
}

.about-us {
  position: relative;
  float: left;
  width: 100%;
  height: inherit;
}

.about-us_text {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 300px;
  bottom: 0;
  padding-bottom: 50px;
}

.about_leftpart {
  position: relative;
  float: left;
  width: 60%;
  height: 330px;
}

.about_leftpart .tf_button {
  line-height: 0px;
}

.about_rightpart {
  position: relative;
  float: left;
  width: 40%;
  height: 330px;
}

.about_leftpart span {
  position: relative;
  float: left;
  width: 100%;
  color: #fff;
}

.about_leftpart span.title {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 30px;
}

.about_leftpart span.description {
  font-size: 16px;
  line-height: 26px;
  font-family: ligh-font;
}

.about_rightpart .about_rightpart_img {
  height: inherit;
  padding: 0px 10px;
}

.about_rightpart .about_rightpart_img img {
  width: initial;
  height: 100%;
  object-position: top;
  object-fit: cover;
}

.tf_button.bottom {
  position: absolute;
  bottom: 0px;
}

.industry-section {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  /* min-height: 500px; */
  background-color: #000047;
  padding: 70px 0px;
}

.industry-section .parent-industry {
  position: relative;
  float: left;
  width: 100%;
  height: inherit;
  display: flex;
}

.industry-section .parent-industry .left-part {
  position: relative;
  float: left;
  width: 60%;
  height: inherit;
}

.industry-section .parent-industry .left-part .boxes {
  padding: 15px;
}

.industry-section .parent-industry .left-part .inside-industry-boxes {
  position: relative;
  float: left;
  width: 100%;
  min-height: 200px;
  background: rgb(238, 238, 238);
}

.industry-section .parent-industry .left-part .inside-industry-boxes {
  background: linear-gradient(
    0deg,
    rgba(0, 9, 71, 0) 30%,
    rgba(151, 151, 151, 0.2) 100%
  );
}

.industry-section .parent-industry .left-part .inside-industry-boxes img {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.industry-section .parent-industry .left-part .inside-industry-boxes span {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.industry-section
  .parent-industry
  .left-part
  .inside-industry-boxes
  span.extra-span {
  bottom: -10px;
}

.big-title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 30px;
  font-weight: 700;
}

.left-part.big-title {
  margin-bottom: 30px;
}

.big-title img {
  position: relative;
  float: left;
  /* width: 50px; */
  height: auto;
  margin-right: 20px;
}

.big-title.color span {
  color: #000047;
}

.big-title span {
  position: relative;
  float: left;
  width: auto;
  height: auto;
  color: #fff;
  /* font-family: black-font; */
  text-transform: uppercase;
}

.industry-section .parent-industry .right-part {
  position: relative;
  float: left;
  width: 40%;
  height: inherit;
  padding-left: 30px;
}

.industry-section .parent-industry .right-part .bg {
  z-index: 10;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.industry-section .parent-industry .right-part .dots {
  position: absolute;
  left: 35%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

.process {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 400px;
  margin-top: 40px;
}

.process .grid .inside_grid .inside_grid__ span {
  margin: 40px 0px;
  position: relative;
  float: left;
  color: #919191;
  font-size: 16px;
  line-height: 24px;
  font-family: ligh-font;
}

.process .process-desc {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 60px;
}

.pright15 {
  padding-right: 15px;
}

.process .process-desc .title-process {
  color: #000047;
  font-size: 25px;
  line-height: 35px;
  position: relative;
  float: left;
  margin-bottom: 50px;
  transition: 0.3s cubic-bezier(0.01, 0.5, 0.67, 0.61) all;
  width: 100%;
}

.pleft5 {
  padding-left: 5px;
}

.process .process-desc .scale-boxes {
  position: relative;
  float: left;
  width: 100%;
  height: 140px;
  background: linear-gradient(rgba(238, 238, 238, 1), rgba(238, 238, 238, 0.2));
  display: flex;
  flex-direction: row;
  transition: 0.3s cubic-bezier(0.01, 0.5, 0.67, 0.61) all;
}

/* .process .process-desc .grid .inside_grid:hover .circle
{
    transform: scaleX(1) translate(-90%, -50%);
    z-index: 99999;
} */

/* .process .process-desc .grid .inside_grid:hover .square-boxes
{
    transform: scale(1) translateX(-70%);
    z-index: 99999;
    background-color: rgba(238,238,238, 1);
} */

.process .process-desc .grid .inside_grid.scale,
.process .process-desc .grid .inside_grid.square {
  opacity: 0.05;
}

/* .process .process-desc .grid .inside_grid:hover .title-process.square
{
    transform: translateX(-34%);
} */
/* .process .process-desc .grid .inside_grid:hover .title-process
{
    transform: translateX(50%);
} */
/* .process .process-desc .grid .inside_grid:hover .scale-boxes
{
    transform: scale(1) translateX(50%);
    z-index: 99999;
    background-color: rgba(238,238,238, 1);
} */
.mTop50 {
  margin-top: 50px;
}

.mBottom50 {
  margin-bottom: 50px;
}

.process .process-desc .scale-boxes.reverse {
  flex-direction: row-reverse;
}

.process .process-desc .scale-boxes .number {
  position: relative;
  float: left;
  width: 80px;
  height: 100%;
  background-color: #00a9ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.process .process-desc .scale-boxes .number span {
  position: relative;
  float: left;
  width: auto;
  height: auto;
  color: #fff;
  font-size: 25px;
  line-height: 35px;
}

.process .process-desc .scale-boxes .description {
  position: relative;
  float: left;
  width: calc(100% - 80px);
  padding: 10px;
}

.process .process-desc .scale-boxes .description .title {
  position: relative;
  float: left;
  width: 100%;
  color: #000047;
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 35px;
  font-family: bold-font;
}

.process .process-desc .scale-boxes .description .description_ {
  position: relative;
  float: left;
  width: 100%;
  font-size: 14px;
  color: #000047;
  line-height: 18px;
  font-size: 14px;
  line-height: 19px;
  font-family: regular-font;
}

.process-desc img.agile {
  display: none;
}

.process .process-desc .square-boxes {
  position: relative;
  float: left;
  height: 280px;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 5px !important;
  transition: 0.3s cubic-bezier(0.01, 0.5, 0.67, 0.61) all;
}

.process .process-desc .square-boxes .inside_grid_ {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 238, 238, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  padding: 35px;
}

.process .process-desc .square-boxes .inside_grid_ span:first-child {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #000047;
  font-size: 22px;
  line-height: 35px;
  position: relative;
  float: left;
  font-family: bold-font;
}

.process .process-desc .square-boxes .inside_grid_ span:last-child {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #000047;
  font-size: 14px;
  line-height: 19px;
  position: relative;
  float: left;
  font-family: regular-font;
}

.process .process-desc .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transition: .3s cubic-bezier(0.01, 0.5, 0.67, 0.61) all; */
}

.pRelative {
  position: relative;
}

.our-team {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 100px;
}

.our-team .grid .inside_grid .inside_grid__ span {
  margin: 40px 0px;
  position: relative;
  float: left;
  color: #919191;
  font-size: 16px;
  line-height: 24px;
  font-family: ligh-font;
}

.our-team .bg-team {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 175px;
  height: 250px;
  object-fit: cover;
}

.our-team .team-pos {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 0px 0px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
}

.our-team .team-pos .team-pos-desc {
  position: relative;
  float: left;
  width: 200px;
  text-align: center;
  height: fit-content;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.our-team .team-pos .team-pos-desc .number {
  position: relative;
  float: left;
  width: auto;
  height: auto;
  color: #00a9ff;
  font-size: 30px;
  line-height: 40px;
  font-weight: 900;
  font-family: black-font;
  height: 40px;
}

.our-team .team-pos .team-pos-desc .desc {
  position: relative;
  float: left;
  color: #dedede;
  font-size: 20px;
  line-height: 30px;
  font-family: ligh-font;
  text-transform: uppercase;
  height: 60px;
}

.present-team {
  position: relative;
  float: left;
}

.present-team .present-team_ {
  position: relative;
  float: left;
  height: 250px;
  padding: 10px;
}

.present-team .present-team_ .inside_present-team_ {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  overflow: hidden;
}

.present-team .present-team_ img {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.present-team .present-team_:hover .inside_present-team_ .team-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 169, 255, 0.2);
  backdrop-filter: blur(10px);
  cursor: pointer;
}

.present-team .present-team_ .inside_present-team_ .team-desc .name {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-family: regular-font;
}

.present-team .present-team_ .inside_present-team_ .team-desc .position {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-family: regular-font;
}

.present-team .load-more {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.present-team .load-more .square {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #000047;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ldf {
  position: relative;
  float: left;
  width: 100%;
  height: fit-content;
  /* margin-top: 40px; */
}

.logosTopImgText {
  display: flex;
  width: 100%;
  /* padding-left: 10px; */
  /* margin-bottom: 24px; */
  flex-wrap: wrap;
}

.newTechWidthText {
  width: 100% !important;
  margin-left: 135px !important;
}

@media (max-width: 1000px) {
  .newTechWidthText {
    margin-left: 15px !important;
  }
}

.flipText {
  justify-content: flex-start;
  margin-left: 15px;
  width: fit-content;
}

.ldf .left-part {
  position: relative;
  float: left;
  width: 50%;
  height: fit-content;
  min-height: 500px;
  /* padding: 0px 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(238, 238, 238, .4); */
  /* padding-left: 30px; */
}

.flipProjects {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.ldf .left-part .logos {
  height: 75px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3ebf3;
  margin: 10px;
  padding: 0;
  margin-left: 0;
  margin-right: 20px;
}

.flipLogos {
  margin-right: 0 !important;
  margin-left: 20px !important;
}

@media (max-width: 1000px) {
  .flipProjects {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flipLogos {
    margin-right: 20px !important;
    margin-left: 0px !important;
  }

  .flipText {
    justify-content: flex-start;
    /* padding-right: 15px; */
    padding-left: 0;
    width: 100%;
    margin-right: 15px;
  }
}

@media (max-width: 420px) {
  .logosMobile {
    width: 100px !important;
    height: 70 !important;
    margin-right: 5px !important;
  }
}

@media (max-width: 350px) {
  .logosMobile {
    width: 80px !important;
    height: 60 !important;
    margin-right: 5px !important;
  }
}

@media (max-width: 320px) {
  .margB {
    margin-bottom: 10px !important;
  }
}

.ldf .left-part .logos img {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  object-fit: contain;
}

/*.ldf .left-part .logos img*/
/*{*/
/*    position: relative;*/
/*    float: left;*/
/*    height: 100px;*/
/*    width: 100px;*/
/*    object-fit: contain;*/
/*}*/
.ldf .right-part {
  position: relative;
  float: left;
  width: 50%;
  height: 500px;
  min-height: 500px;
}

.imgChessLeft {
  object-fit: cover;
  margin-bottom: 20px;
}

.ldf .right-part img {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reverse {
  position: relative;
  float: left;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.parent-partners {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 100px;
  background-color: rgba(238, 238, 238, 0.4);
}

.parent-partners .first,
.parent-partners .second {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 50px;
}

.parent-partners .second {
  padding-bottom: 100px;
}

.projects {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.projects .inside-project-related-title {
  width: 100%;
  color: #000347;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.projects.blogs {
  padding-top: 150px;
  padding-bottom: 50px;
}

.projects .header-img {
  margin-top: 100px;
}

.projects .projects-content {
  margin-top: 150px;
}

.projects .inside_grid__ span {
  margin: 40px 0px;
  position: relative;
  float: left;
  color: #2d2d40;
  font-size: 16px;
  line-height: 24px;
  font-family: book-font;
}

.projects .inside_projcet {
  position: relative;
  float: left;
}

.projects .inside_projcet:nth-child(odd) {
  padding-right: 15px;
}

.projects .inside_projcet:nth-child(even) {
  padding-left: 15px;
}

.projects .inside_projcet .inside_projcet_ {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding-bottom: 50px;
}

.projects .inside_projcet .inside_projcet_ img {
  position: relative;
  float: left;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.projects .inside_projcet .inside_projcet_ a.title {
  position: relative;
  float: left;
  width: 100%;
  font-family: bold-font;
  /* change to black font */
  font-size: 25px;
  line-height: 40px;
  color: #000047;
  padding-top: 15px;
}

.projects .inside_projcet .inside_projcet_ span.description {
  position: relative;
  float: left;
  width: 100%;
  font-family: regular-font;
  /*  */
  font-size: 16px;
  line-height: 24px;
  color: #919191;
  margin: 20px 0px;
}

.projects .inside_projcet .inside_projcet_ span,
.projects .inside_projcet .inside_projcet_ a {
  position: relative;
  float: left;
  width: 100%;
  font-family: regular-font;
  /*  */
  font-size: 16px;
  line-height: 24px;
  color: #000047;
  cursor: pointer;
}

.projects .inside_projcet .inside_projcet_.work-together {
  border: 1px dashed #cbcbcb;
  background-color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 0px;
  cursor: pointer;
}

.projects .inside_projcet .inside_projcet_.work-together span {
  font-family: regular-font;
  /* change to black font */
  color: #d5d5d5;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
}

.lines-bg {
  background-image: url('../../assets/img/lines-bg.svg');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  height: 550px;
}

.projects-bg {
  background-color: #f0f4f8;
  height: 625px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.inside-project {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding-top: 120px;
  padding-bottom: 50px;
}

@media (max-width: 620px) {
  .inside-project {
    padding-top: 60px;
  }
}

.inside-project .bottom-line {
  position: relative;
  float: left;
  width: 100%;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 0px;
  margin-top: 100px;
}

.inside-project .boxes {
  position: relative;
  float: left;
  width: 100%;
  height: 150px;
  padding: 30px;
  display: flex;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.5);
}

.inside-project .boxes .img-box {
  position: relative;
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.inside-project .boxes .box-content {
  position: relative;
  float: left;
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inside-project .boxes .box-content .username {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.inside-project .boxes .box-content .username span {
  color: #000047;
  font-size: 20px;
  line-height: 30px;
}

.inside-project .boxes .box-content .description {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.inside-project .boxes .box-content .description span {
  color: #979797;
  font-size: 14px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inside-project .inside-project-title {
  position: relative;
  float: left;
  width: 100%;
  font-family: bold-font;
  font-size: 30px;
  line-height: 40px;
  color: #00a9fc;
  /* margin-top: 100px; */
  margin-bottom: 30px;
  text-transform: uppercase;
}

.inside-project .projects {
  padding-top: 100px;
}

.inside-project .projects .inside-project-title {
  margin-top: 0px;
}

.inside-project .inside-project-img {
  width: 100%;
  object-fit: contain;
  height: auto;
}

.project-image-box-container {
  height: 460px;
  position: relative;
}

.inside-project .project-details-container {
  margin-top: 80px;
}

.inside-project .project-details-container h1 {
  font-size: 45px;
  font-weight: bold;
  color: #000347;
  margin-bottom: 42px;
}

.text-editor.ql-editor.project-inside {
  padding-top: 40px;
}

.text-editor.ql-editor.project-inside p {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-blog p {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-blog h4 strong h2 h1 h3 h5 h6 em {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-blog p span {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.project-inside span {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.project-inside em {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
  text-decoration: none !important;
  font-style: normal !important;
}

.text-editor.ql-editor.project-inside strong {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.project-inside p,
.text-editor.ql-editor.project-inside h4 {
  line-height: 30px !important;
  font-weight: normal !important;
  font-family: ligh-font !important;
  font-size: 18px !important;
  color: #2d2d40 !important;
}

.text-editor.ql-editor.project-inside ul li {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  line-height: 26px !important;
  font-weight: normal !important;
}

.inside-project-images-holder {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.inside-project-images-holder div:first-child {
  grid-column: 1 / -1;
}

.inside-project-images-holder .inside-project-images-item {
  height: 450px;
  background-color: black;
}

.inside-project .project-details-container h6 {
  font-size: 20px;
  font-family: book-font;
  color: #2d2d40;
  padding-right: 100px;
  margin-bottom: 30px;
  line-height: 30px;
  font-weight: normal;
}

.inside-project .project-details-container p {
  color: #000347;
  line-height: 30px;
  font-size: 16px;
}

.inside-project .project-image-box-container .little-box {
  height: 65px;
  width: 85px;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.inside-project .project-image-box-container .item-image {
  position: absolute;
  height: 390px;
  width: 495px;
  z-index: 4;
  left: 30px;
  top: 55px;
}

.inside-project .project-image-box-container .item-image img {
  height: 100%;
  object-fit: contain;
}

.inside-project .project-image-box-container .big-box {
  height: 265px;
  width: 354px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.inside-project .blog-header .info-text .date {
  color: #2d2d40;
  font-size: 16px;
}

.inside-project .blog-header h1 {
  font-size: 30px;
  margin-bottom: 40px;
  text-transform: none;
  color: #000347;
}

.inside-project .other-details {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inside-project .other-details .tags {
  font-size: 16px;
  color: #2d2d40;
}

.inside-project .other-details .social-icon-wrapper {
  display: flex;
  align-items: center;
}

.inside-project .other-details .social-icon-wrapper a {
  border: 2px solid #000347;
  font-size: 18px;
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inside-project .other-details .social-icon-wrapper a:first-of-type {
  /* margin-left: 10px; */
  /* border-right: none; */
}

.inside-project .other-details .social-icon-wrapper a:last-child {
  /* border-left: none; */
}

.text-editor.ql-editor.inside-blog {
  margin-top: 60px;
}

.text-editor.ql-editor.inside-blog p {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor {
  width: 1000px;
  padding-left: 0;
}

.text-editor.ql-editor.inside-career-editor h2 {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor p strong {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor p span {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor p {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor span {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor ul {
  padding-left: 0;
}

.text-editor.ql-editor.inside-career-editor ul li {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.text-editor.ql-editor.inside-career-editor ul li::before {
  color: #2d2d40 !important;
  font-size: 18px !important;
  font-family: ligh-font !important;
  font-weight: normal !important;
}

.inside-project .inside-career-title {
  font-size: 30px;
  font-family: black-font;
  text-transform: uppercase;
  color: #00a9fc;
  margin: 30px 0 20px 0;
}

.inside-project .inside-career-benefits-title {
  font-size: 30px;
  font-family: black-font;
  text-transform: uppercase;
  color: #000347;
  margin-bottom: 20px;
}

.heightMobile {
  height: fit-content;
  margin-bottom: 30px;
}

.inside-project .benefits-inside-career {
  border-bottom: 1px solid #e3e3e3;
  height: fit-content;
}

.open_position__right_bottom_.inside-career-location {
  margin-bottom: 60px;
}

.link-wrapp {
  width: 100%;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  /* margin-top: -23px; */
  position: relative;
}

.owl-theme .owl-dots .owl-dot span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #d8d8d8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #00a9ff;
}

@media (max-width: 1000px) {
  .owl-dots {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.owl-carousel.owl-loaded.inside-project-carousel {
  height: fit-content;
}

.item.inside-project-item {
  background-color: #f0f4f8;
}

.item.inside-project-item .testimonial-item {
  margin-top: 40px;
}

.item.inside-project-item .testimonial-item p {
  margin: 30px 0 0 0;
}

.item.inside-project-item .testimonial-item p.testimonial-dscp {
  margin: 45px 90px 0 90px;
}

.item.inside-project-item .testimonial-item .testimonial-name {
  margin: 0;
}

.inside-project-navigation {
  display: flex;
  justify-content: space-between;
}

.inside-project-navigation .nav-left,
.inside-project-navigation .nav-right {
  cursor: pointer;
}

.inside-project-navigation .nav-left img {
  margin-right: 20px;
}

.inside-project-navigation .nav-right img {
  margin-left: 20px;
}

.section-divider {
  border-bottom: 1px solid #e3e3e3;
  margin: 50px 0 35px 0;
}

.career .about_leftpart {
  width: 60%;
}

.career .first-section {
  height: auto;
  margin-top: 120px;
}

.career .benefits {
  position: relative;
  float: left;
  width: 100%;
  min-height: 500px;
  height: auto;
  padding-bottom: 100px;
}

.career .benefits .inside_grid__ span {
  margin: 40px 0px;
  position: relative;
  float: left;
  color: #919191;
  font-size: 16px;
  line-height: 24px;
  font-family: ligh-font;
}

.career-banner-section-text {
  display: flex;
  height: 510px;
  flex-direction: column;
  justify-content: center;
  margin-right: 100px;
  border-bottom: 1px solid #d6d6d6;
}

.carrerBannerTextNew h4 {
  font-family: book-font;
  font-size: 16px;
  line-height: 30px;
  color: #2d2d40;
  text-transform: uppercase;
}

.carrerBannerTextNew h1 {
  font-size: 45px;
  font-family: black-font;
  line-height: 50px;
  color: #000347;
  margin-top: 50px;
}

.carrerBanner {
  min-height: 700px;
  box-sizing: border-box;
  display: flex;
  flex: 10;
}

.carrerBannerImageDiv {
  position: relative;
  flex: 5;
}

.carrerBannerImage {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
}

.carrerBannerImageLayer {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
  height: 100%;
  background: linear-gradient(to right, white, transparent);
}

.carrerBannerTextNew {
  display: flex !important;
  justify-content: center !important;
  /* align-items: center !important; */
  flex: 5;
  padding-left: 10px;
}

.companyAgileBigImagesDiv {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  flex: 10;
  gap: 80px;
}
.agile-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.agile-wrapper-textbox {
  width: 350px;
}
.companyAglieImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 700px;
  min-width: 450px;
  flex: 5;
}

.deHeader {
  line-height: 80px !important;
}

@media (max-width: 600px) {
  .companyAglieImg {
    min-width: 100%;
  }
}
@media (min-width: 1000px) {
  .changeLanguageMobile {
    display: none;
  }
}

@media (max-width: 999px) {
  .changeLanguageMobile {
    /* margin-left: 20px !important; */
    display: none;
  }
  .nav-menu__inside .a {
    display: none !important;
  }
}

.carrerBannerTextNew {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 40%;
}

.h1AbsoluteDIv {
  position: relative;
  height: 70px;
  display: flex;
}

.h1AbsoluteDIv h1 {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  max-width: 950px;
}

@media (max-width: 650px) {
  .h1AbsoluteDIv {
    position: relative;
    height: 100%;
    min-height: fit-content;
  }

  .carrerBanner {
    min-height: fit-content;
    margin-top: 70px;
    width: 100%;
  }

  .h1AbsoluteDIv h1 {
    position: relative;
    font-size: 20px !important;
    width: 100%;
    margin: 0 !important;
    line-height: 35px;
  }

  .carrerBannerImage {
    display: none;
  }

  .carrerBannerImageLayer {
    display: none;
  }

  .open_position {
    padding-top: 20px !important;
  }

  .carrerBannerTextNew p {
    width: 100% !important;
  }

  .carrerBannerTextNew {
    width: 100%;
    min-width: 80vw;
  }
}

.carrerBannerImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carrerBannerTextNew h1 span {
  color: #00a9fc;
}

.carrerBannerTextNew p {
  font-family: book-font;
  color: #2d2d40;
  margin: 40px 0 20px 0;
  line-height: 24px;
}

.career-banner-section-img {
  width: 100%;
  height: 510px;
  display: flex;
  justify-content: flex-end;
  /* padding-left: 20px; */
  background-size: cover !important;
}

.benefits_ {
  position: relative;
  float: left;
  height: 65px;
}

.benefits_ .benefits__ {
  position: relative;
  float: left;
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  max-width: 200px;
  justify-content: flex-start;
}

@media (max-width: 500px) {
  .noPleft {
    padding-left: 0px !important;
  }
}

.benefits_:nth-child(even) {
  padding-left: 5px;
}

.benefits_:nth-child(odd) {
  padding-left: 0px;
}

.benefits_ .benefits__ .benefits_left {
  position: relative;
  float: left;
  width: 40px;
  height: 40px;
  background-color: #f0f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
}

.benefits_ .benefits__ .benefits_left img {
  width: 40px;
  height: 40px;
}

.benefits_ .benefits__ .benefits_right {
  position: relative;
  float: left;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  align-items: center;
}

.benefits_ .benefits__ .benefits_right span {
  font-size: 16px;
  line-height: 19px;
  font-family: ligh-font;
  color: #000047;
}

.open_position {
  position: relative;
  float: left;
  padding-bottom: 50px;
  padding-top: 100px;
}

.open_position_ {
  padding: 0 15px;
}

.open_position__ {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  min-height: 320px;
  padding: 20px;
  background-color: #f0f4f8;
  margin-bottom: 20px;
  border: 3px solid transparent;
  transition: 0.3s all ease-in-out;
}

.open_position__:hover {
  border: 3px solid #00a9fc;
  transition: 0.3s all ease-in-out;
}

.open_position__img {
  height: 100px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open_position__img img {
  height: 100%;
}

.open_position__ .open_position__left {
  position: relative;
  float: left;
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open_position__ .open_position__left img {
  width: 65px;
  height: 65px;
}

.open_position__ .open_position__right {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.open_position__ .open_position__right .open_position__right_top a {
  font-size: 30px;
  line-height: 40px;
  font-family: black-font;
  color: #000047;
  cursor: pointer;
}

.inside-project .open_position__right_bottom {
  padding-left: 0px !important;
}

.mBottom10 {
  margin-bottom: 10px !important;
}

.inside-project .open_position__right_bottom,
.open_position__ .open_position__right .open_position__right_bottom {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.inside-project .open_position__right_bottom_,
.inside-project .open_position__right_bottom_ img,
.open_position__ .open_position__right .open_position__right_bottom_,
.open_position__ .open_position__right .open_position__right_bottom_ img {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
}

.inside-project .open_position__right_bottom_ img,
.open_position__ .open_position__right .open_position__right_bottom_ img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inside-project .open_position__right_bottom_ span,
.open_position__ .open_position__right .open_position__right_bottom_ span {
  font-family: ligh-font;
  font-size: 18px;
  color: #000347;
  text-transform: uppercase;
}

time {
  font-family: ligh-font;
  font-size: 18px;
  color: #000347;
  text-transform: uppercase;
}

.open_position__
  .open_position__right
  .open_position__right_bottom_:first-of-type {
  margin-bottom: 15px;
}

.inside-career-title {
  position: relative;
  float: left;
  text-transform: uppercase;
  width: 100%;
  height: auto;
  font-family: bold-font;
  font-size: 30px;
  line-height: 26px;
  color: #000347;
}

.popup-text {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-family: regular-font;
  font-size: 14px;
  line-height: 24px;
  color: #919191;
  margin-top: 20px;
}

.second-section-content span {
  margin: 20px 0px;
  position: relative;
  float: left;
  color: #919191;
  font-size: 16px;
  line-height: 24px;
  font-family: ligh-font;
}

.apply-now {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
}

.apply-now .apply-btn {
  background-color: white;
  max-width: 110px;
  height: 45px;
  color: #000347;
  border: 1px solid #000347;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.form-apply {
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 11;
  top: 0px;
  display: none;
}

.form-apply[is-active='true'] {
  display: block;
}

.form-apply .form-apply_ {
  width: 500px;
  height: 570px;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.form-apply__ {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
}

.form-apply__ .inside-career-form-title {
  font-size: 20px;
  font-family: black-font;
  color: #000347;
  line-height: 24px;
  position: absolute;
  top: -30px;
}

.form-apply__ .apply-now .tf_button {
  float: right !important;
}

.contact-form.career {
  margin-top: 30px;
}

.contact-form.career .file-phone-data {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.contact-form.career .email {
  margin-right: 25px;
}

.contact-form.career .apply-now button {
  color: #000347;
  background-color: white;
  font-size: 16px;
  font-family: regular-font;
  line-height: 19px;
  padding: 13px 20px;
  border: 1px solid #000347;
}

.contact-form.career .phone-number,
.contact-form.career .email {
  width: 50%;
}

.contact-form.career .username label,
.contact-form.career .email label,
.contact-form.career .description label {
  padding-bottom: 10px;
  color: #2d2d40;
  font-size: 16px;
}

.contact-form.career .file label {
  margin-bottom: 10px;
}

.x {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  color: #000047;
  cursor: pointer;
  z-index: 999;
}

.x span {
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #000347;
}

.x span:first-child {
  transform: rotate(45deg);
  top: 10px;
  left: 2.5px;
}

.x span:last-child {
  transform: rotate(-45deg);
  top: 10px;
  left: 2.5px;
}

.oHidden {
  overflow: hidden;
}

.insid_blog {
  position: relative;
  float: left;
  height: auto;
}

.insid_blog .inside_blog_ {
  position: relative;
  float: left;
  height: 100%;
}

.insid_blog .inside_blog_ .insid_blog_first_ {
  position: relative;
  float: left;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
}

.insid_blog .inside_blog_ .insid_blog_first_ span.title {
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 32px;
  line-height: 39px;
  color: #000047;
  font-family: bold-font;
}

.insid_blog .inside_blog_ .insid_blog_first_ span.description {
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  color: #919191;
  font-family: regular-font;
  padding: 30px 0px;
}

.insid_blog .inside_blog_ .insid_blog_first_ img.big-img {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog .load-more {
  border: 1px solid #707070;
  color: #000347;
  font-size: 18px;
  padding: 13px 45px;
  text-decoration: none;
  background-color: #fff;
}

.pright80 {
  padding-right: 80px;
}

.inside_small {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.inside-projects .inside_small {
  margin-top: 0px;
}

.inside_small .inside_blog-small {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.blogLayer {
  background: linear-gradient(transparent, rgba(24, 23, 23, 0.6));
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  padding-bottom: 15px;
  font-weight: normal;
}

.blogLayerDiv {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inside_blog-small div.bottom-border {
  margin-right: 30px;
  margin-top: 40px;
  border-bottom: 1px solid #d6d6d6;
}

.inside_blog-small .insid_blog_first_ {
  position: relative;
  float: left;
}

.inside_blog-small .insid_blog_first_.img-part {
  width: 100%;
  height: 330px;
  position: relative;
}

.smooth-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.inside_blog-small .insid_blog_first_.img-part img {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  padding-right: 30px;
}

.inside_blog-small:hover .insid_blog_first_.img-part .blogLayerDiv {
  opacity: 1;
}

.inside_blog-small .insid_blog_first_.text-part {
  padding-right: 100px;
}

.inside_blog-small .insid_blog_first_.text-part span {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  float: left;
  width: 100%;
}

.inside_blog-small .insid_blog_first_.text-part span.category {
  font-size: 14px;
  line-height: 17px;
  font-family: regular-font;
  color: #00a9ff;
  padding-bottom: 20px;
}

.inside_blog-small .insid_blog_first_.text-part span.small-title {
  margin-top: 7px;
  font-size: 20px;
  line-height: 26px;
  font-family: regular-font;
  color: #2d2d40;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 19px;
  min-height: 52px;
}

.inside_blog-small .insid_blog_first_.text-part span.date {
  font-size: 16px;
  line-height: 26px;
  font-family: book-font;
  color: #2d2d40;
  padding-top: 20px;
}

.apply-now.blog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.info-text {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.info-text span {
  font-size: 14px;
  line-height: 17px;
  font-family: regular-font;
  color: #919191;
}

.info-text span.category {
  color: #00a9ff;
  text-transform: uppercase;
}

.item-img {
  height: 450px;
}

.item-img img {
  height: 100%;
  object-fit: cover;
}

.owl-carousel.owl-loaded {
  height: 450px;
}

.owl-carousel.owl-loaded .owl-stage-outer .owl-stage,
.owl-carousel.owl-loaded .owl-stage-outer .owl-stage .owl-item,
.owl-carousel.owl-loaded .owl-stage-outer .owl-stage .item,
.owl-carousel.owl-loaded .owl-stage-outer .owl-stage .item img,
.owl-carousel.owl-loaded .owl-stage-outer {
  height: 100%;
  object-fit: cover;
}

.some-partners {
  /*position: relative !important;*/
  /*float: left !important;*/
  /*width: auto !important;*/
  /*height: auto !important;*/
  color: #000047 !important;
  font-family: black-font !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.popup-input {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.popup-input input {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  border: 1px solid #dedede;
  padding-left: 15px;
  margin-top: 10px;
}

.popup-input textarea {
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
  border: 1px solid #dedede;
  padding-left: 15px;
  resize: none;
}

.popup-input input::placeholder {
  color: #dedede;
}

.popup-upload {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  border: 1px solid #dedede;
  cursor: pointer;
  margin-top: 10px;
}

.popup-upload label {
  position: absolute;
  font-size: 14px;
  line-height: 17px;
  color: #bbbbbb;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup-upload .additional-div {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #e2e2ea;
  height: 44px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 999;
  padding: 150px;
}

.project-popup .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
}

.project-popup img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 99;
}

.contact-form.career .file .popup-upload input {
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block !important;
  z-index: 2;
  cursor: pointer;
}

.contact-form.career .file .popup-upload label div {
  z-index: 1;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  /* background-image: url("../tframeLoaderB.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%) blur(4px);
  transform: scale(1.1);
}

.loaderContainer {
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.loader-holder {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gifContainer {
  justify-content: center;
  position: absolute;
  z-index: 9999999999;
}

.gifHat {
  width: 200px;
  object-fit: cover;
}

.slashLeft {
  height: 100px;
  width: 80px;
  object-fit: cover;
  position: absolute;
  left: 50px;
  top: 50px;
  animation: leftSlash 1s infinite;
}

.slashRight {
  height: 100px;
  width: 82px;
  object-fit: cover;
  position: absolute;
  left: 50px;
  top: 50px;
  animation: rightSlash 1s infinite;
}

.loaderContainer.d-block {
  display: block;
}

@keyframes leftSlash {
  0% {
    left: 50px;
  }

  50% {
    left: 84px;
  }

  100% {
    left: 50px;
  }
}

@keyframes rightSlash {
  0% {
    left: 50px;
  }

  50% {
    left: 17px;
  }

  100% {
    left: 50px;
  }
}

.loaderContainer.d-none {
  display: none;
}

.projects-wrapper .see-more-btn {
  font-size: 18px;
  color: #000347;
  border: 1px solid #707070;
  padding: 13px 25px;
  text-decoration: none;
}

.projects-container .project-item-wrapper:nth-child(even) {
  /* margin-top: 30px; */
  padding-top: 0;
  padding-right: 0;
}

/* .projects-container .project-item-wrapper:nth-child(even) .project-item {
    padding-right: 0;
} */
.projects-container
  .project-item-wrapper:nth-child(even)
  .project-item
  .project-name {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

/* .projects-container .project-item-wrapper:nth-child(odd) .project-item {
    padding-left: 0;
} */
.projects-container
  .project-item-wrapper:nth-child(odd)
  .project-item
  .project-name {
  position: absolute;
  left: 10px;
  bottom: 40px;
}

.project-item {
  height: 450px;
  max-width: 100%;
  padding: 15px;
  padding-left: 0px;
  padding-top: 0;
  /* padding-right: 0px; */
}

.projects-container .project-item:hover .project-details {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.projects-container .project-item .project-details {
  position: absolute;
  height: 450px;
  width: 100%;
  padding: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  background: linear-gradient(black, transparent);
}

.projects-container .project-item a {
  text-decoration: none;
  color: #fff;
  font-family: book-font;
  font-size: 14px;
}

.projects-container .project-item .project-name h1 {
  font-size: 24px;
  color: #ffffff;
  font-family: black-font;
  width: 100%;
  height: 100%;
}

.projects-container .project-item .project-image {
  height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.navButton {
  border: 1px solid #00a7fc;
  height: fit-content;
}

.projectHoverLayer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  color: white;
  display: flex;
  align-items: flex-end;
  background: transparent;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 10px;
}

.underLineProject {
  width: 20%;
  height: 3px;
  background-color: #00a9ff;
  position: absolute;
  bottom: 5px;
  left: 10px;
}

.hoverFillDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  background-color: transparent;
}

.fillDivBlue {
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: 10;
  background-color: #00a9ff;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.notMobileProjectsText {
  display: block;
}

.mobileProjectsText {
  display: none;
}

@media (max-width: 620px) {
  .noPaddingProjects {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mobileProjectsText {
    display: block;
  }

  .notMobileProjectsText {
    display: none;
  }

  .project-item {
    padding-right: 0 !important;
  }

  .projects-container .project-item-wrapper:nth-child(odd) {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .padding15Horizontal {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.tagProject {
  color: #2d2d40;
  border-radius: 7px;
  margin-left: 5px;
  text-transform: capitalize;
}

.tagProject span {
  font-size: 16px;
  font-family: ligh-font;
  font-weight: normal;
}

.inside_blog-small:hover .insid_blog_first_.text-part span {
  color: #1ca8fc !important;
}

.projectHoverText {
  z-index: 120;
  position: relative;
  font-size: 22px;
  z-index: 100;
  padding: 10px;
  width: fit-content;
}

.projectHoverLayer:hover .hoverFillDiv .fillDivBlue {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.projectHoverLayer:hover {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

@media (max-width: 430px) {
  .inside-project .other-details {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 90px;
  }
}

.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
  font-size: 100px !important;
  font-family: ligh-font !important;
  font-weight: 1 !important;
}

.owl-carousel .owl-nav button.owl-prev {
  left: 0px;
}

.owl-carousel .owl-nav button.owl-next {
  right: 0px;
}
