.bigSquareDiv {
  width: 100%;
  height: fit-content;
  min-height: 600px;
  background: url("../../../assets/img/aboutImages/bigSquare.svg");
  background-size: cover;
  background-position: 100% 50%;
  position: relative;
  margin-bottom: 50px;
}

.bigSquareLayer {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  height: calc(100%);
}

.bigSquareDivSquares {
  color: white;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.growLinearColor{
  display: none;
}

.bigSquareContainer {
  position: absolute;
  width:33.333333333%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  font-weight: ligh-font !important;
  cursor: pointer;
  z-index: 9;
  transition: all 0.5s ease-in-out; 
}

.bigSquareContainer.div1 {
  top: 0;
  left: 0;
}
.bigSquareContainer.div2 {
  top: 0;
  right: 33.3%;
  transform: translateX(-0%);
  border-right: none;
  border-left: none;
}
.bigSquareContainer.div3 {
  top: 0;
  right: 0;
}
.bigSquareContainer.div4 {
  bottom: 0px;
  left: 0;
  border-top: none;

}
.bigSquareContainer.div5 {
  bottom: 0px;
  right: 33.5%;
  transform: translateX(-0%);
  border-right: none;
  border-left: none;
  border-top: none;

}
.divDescriptionCards.animation2{
text-align: left;
}
.bigSquareContainer.div6 {
  bottom: 0px;
  right: 0;
  border-top: none;

}
.animation2 {
  z-index: 10;
}
.growLinearColor{
  position: absolute;
  width:100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(0, 3, 71, 0.4);
  cursor: pointer;
  z-index:19;
  opacity: 0;
}
.growLinearColor.animation2{
  opacity: 1;

}

.bigSquareContainer.animation2 p{
  z-index: 20;
  color: white;
}
.bigSquareContainer.animation2 .closeBigCard{
  z-index: 20;
}
.bigSquareContainer.animation2 .moreDetailsBigSquare{
  z-index: 20;
}

.bigSquareContainer.animation2 img{
  z-index: 20;
}
.bigSquareContainer.animation2 {
  width: 66.8%;
  height: 100%; 
  animation-name: becomeBig2;
  animation-duration: 0.5s;
  background-image: url("../../../assets/img/aboutImages/bigSquare.svg");
  background-size: 150%;
  background-position: left;
}
.bigSquareContainer.animation2 .moreDetailsBigSquare {
  display: none !important;
}
.bigSquareContainer.div3.animation2 {
  width: 66.8%;
  height: 100%; 
  animation-name: becomeBig2;
  animation-duration: 0.5s;
  background-image: url("../../../assets/img/aboutImages/bigSquare.svg");
  background-size: 150%;
  background-position: right;
}
.bigSquareContainer.div6.animation2 {
  width: 66.8%;
  height: 100%; 
  animation-name: becomeBig2;
  animation-duration: 0.5s;
  /* background-color:  !important; */
  background-image: url("../../../assets/img/aboutImages/bigSquare.svg");
  background-size: 150%;
  background-position: right;
}
.bigSquareContainer.false {
  animation-name: becomeSmall;
  animation-duration: 0.5s;
}

@keyframes becomeBig2 {
  from {
    z-index: 12;
    width: 33%;
    height: 50%;
  }
  to {
    width: 66.8%;
    height: 100%;
    z-index: 12;
  }
}
@keyframes becomeSmall {
  0% {
    z-index: 10;
  }
  99% {
    z-index: 10;
  }
  100% {
    z-index: 9;
  }
}


.moreDetailsBigSquare {
  position: absolute;
  bottom: 20%;
  color: #1ca8fc;
  font-size: 14px;
  opacity: 0;
  font-family: ligh-font;
  transition: all 0.5s ease-in-out;
}
.bigSquareContainer:hover .moreDetailsBigSquare {
  opacity: 1 !important;
}

@media (max-width: 850px) {
  
  .bigSquareDiv {
    width: 100%;
    height: fit-content;
  }
}

@media (max-width: 850px) {
  
  .bigSquareContainer {
    position: relative;
    height: 300px;
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
  }
  .bigSquareContainer.div1 {
    top: 0;
    left: 0;
  }
  .bigSquareContainer.div2 {
    top: 0;
    right: 0;
    transform: translateX(-0%);

  }
  .bigSquareContainer.div3 {
    top: 0;
    right: 0;
  }
  .bigSquareContainer.div4 {
    bottom: 0px;
    left: 0;
  
  }
  .bigSquareContainer.div5 {
    bottom: 0px;
    right: 0;
    transform: translateX(-0%);
  }
  .bigSquareContainer.div6 {
    bottom: 0px;
    right: 0;
  }
  .bigSquareContainer.animation2 {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 900px;
    width: 100% !important;
    animation-name: becomeBigMobileBS;
    animation-duration: 0.8s;
    background-image: none;
    padding-left: 5%;
    padding-right: 5%;
    transition: all 0.5s ease-in-out;
  }
  .bigSquareContainer.div3.animation2 {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 900px;
    width: 100% !important;
    animation-name: becomeBigMobileBS;
    animation-duration: 0.8s;
    background-image: none;
  }
  .bigSquareContainer.div6.animation2 {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 900px;
    width: 100% !important;
    animation-name: becomeBigMobileBS;
    animation-duration: 0.8s;
    background-image: none;
  }
  
  .growLinearColor{
    display: none;
  }
}



@keyframes becomeBigMobileBS {
  from {
    z-index: 10;
    height: 300px;
  }
  to {
    height: 900px;
    z-index: 10;
  }
}
@keyframes becomeBigMobileText {
  from {
    overflow: hidden;
  }
  to {
    max-height: fit-content;
    overflow: visible;
  }
}
@keyframes becomeSmallMobile {
  0% {
    z-index: 10;
  }
  99% {
    z-index: 10;
  }
  100% {
    z-index: 9;
  }
}