.blogImageContainer {
  width: 100%;
  height: 70vh;
  position: relative;
}
.blogBigImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blogImageContainerText {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 10;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  display: flex;
  align-items: flex-end;
  padding-bottom: 5%;
  padding-left: 7%;
}

.blogP {
  font-size: 20px;
}
.blogH {
  margin-top: 10px;
  font-size: 40px;
  font-family: black-font;
}

@media (max-width: 700px) {
  .blogH {
    margin-top: 10px;
    font-size: 25px;
  }
}

