.whoWeAreDiv {
  width: 1446px;
  height: fit-content;
  margin-bottom: 100px;
  padding-right: 15px;
  padding-left: 15px;
}

.agileDiv {
  margin-bottom: 100px;
}
.aboutusContainerSecond {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f8;
  padding-top: 70px;
}
.whoWeAreDivTitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.whoWeAreDivText {
  display: flex;
  flex: 10;
  box-sizing: border-box;
}

.whoWeAreDivText1 {
  flex: 5;
  padding-right: 50px;
}
.whoWeAreDivText2 {
  flex: 5;
  padding-left: 30px;
  padding-right: 30px;
}

.aboutusContainerSecondBox {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  background: url('../img/aboutImages/background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 500px !important;
  padding-left: 5%;
  padding-right: 5%;
}
.aboutusContainerSecondBox1 {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  background: url('../img/aboutImages/background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px -500px !important;
  padding-left: 5%;
  padding-right: 5%;
}
.squaresContainer {
  position: relative;
}

.whoAreWeDivBoxes {
  width: 1446px;
  height: fit-content;
  /* padding-bottom: 50px; */
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 900px) {
  /* .whoWeAreDiv {
    padding-left: 0%;
    padding-right: 0%;
  } */
  .whoWeAreDivText {
    flex-direction: column;
  }
  .whoWeAreDivText1 {
    flex: 5;
    padding-right: 0px;
  }
  .whoWeAreDivText2 {
    flex: 5;
    padding-left: 0px;
    margin-top: 30px;
  }
}
.h2Tags {
  font-size: 30px;
  font-family: black-font;
}

.h2Tags.deText {
  font-size: 30px;
  font-family: black-font;
}

.ptags {
  font-size: 20px;
  font-family: ligh-font;
}
.headPTag {
  font-size: 18px;
  color: #2d2d40 !important;
  font-family: ligh-font;
}
.linesMobile {
  height: 64px !important;
}

@media (max-width: 1100px) {
  .paddingNone {
    padding: 0 !important;
  }
  .paddringtMobile {
    padding-right: 5%;
  }
}
@media (max-width: 700px) {
  .aboutUsNewLeftContainer {
    margin-top: 0 !important;
  }
}
@media (max-width: 700px) {
  .aboutusContainerSecondBox1 {
    padding-left: 0%;
    padding-right: 0%;
  }
  .padleftCom {
    padding-left: 5%;
    padding-right: 5%;
  }
  .h2Tags {
    font-size: 20px !important;
  }
  .linesMobile {
    height: 50px !important;
    margin-right: 5px !important;
  }
  .ptags {
    font-size: 16px !important;
  }
  .headPTag {
    font-size: 16px !important;
  }
  .paddingNone {
    padding: 0 !important;
  }
  .wrapMobile {
    display: flex !important;
    flex-wrap: nowrap !important;
    height: fit-content !important;
  }
}

@media only screen and (max-width: 850px) {
  .aboutusContainerSecondBox {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}
@media only screen and (max-width: 768px) {
  .whoWeAreDiv {
    padding-left: 5%;
    padding-right: 5%;
  }
  /* .whoAreWeDivBoxes {
    padding-left: 5%;
    padding-right: 5%;
  } */
  .whoWeAreDivTitle {
    padding-left: 0px !important;
    padding-right: 5%;
  }

  .whoWeAreDivText {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 992px) {
  .whoWeAreDiv {
    width: 970px;
  }
  .whoAreWeDivBoxes {
    width: 970px;
  }
}
@media only screen and (min-width: 1200px) {
  .whoWeAreDiv {
    width: 1170px;
  }
  .whoAreWeDivBoxes {
    width: 1170px;
  }
}
@media only screen and (min-width: 1500px) {
  .whoWeAreDiv {
    width: 1446px;
  }
  .whoAreWeDivBoxes {
    width: 1446px;
  }
}
