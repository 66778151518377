.languageDropDown {
  position: relative;
  width: 50px;
  height: 100%;
}

.languageDropDown .dropDownLang {
  position: absolute;
  margin-top: 2px;
}

.languageDropDown .dropDownLang .dropDownItem {
  width: 50px;
  height: 23px;
  border-left: 2px solid #00a9ff !important;
  padding-left: 10px;
}
.mobileDropdown .dropDownLang .dropDownItem {
  width: 50px;
  height: 23px;
  border-left: 2px solid #00a9ff !important;
  padding-left: 10px;
  font-size: 18px;
}
.mobileDropdown {
  display: none;
}


@media (max-width: 1000px) {
  .mobileDropdown {
    display: block !important;
    color: white;
    margin-left: 20px;
    cursor: pointer;
    /* font-weight: normal; */
    font-size: 18px;
    margin-top: 20px;
  }  
  .languageDropDown {
    display: none !important;
  }
  .langActive {
    background-color: #00a9ff !important;
    color: white !important;
  }
}
