.projectDetails {
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f8;
}

.projectDetailsBanner {
  max-width: 1200px;
  width: 1200px;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex: 10;
}
.projectDetailsBannerLeft {
  display: flex;
  flex: 5;
  flex-direction: column;
  text-align: left !important;
  justify-content: center;
}
.spanProjects{
  color: #2D2D40 !important;
  font-family: ligh-font !important;
  font-size: 18px !important;
  width: 70%;
}
@media(max-width:800px){
  .spanProjects{
    width: 90%;
  }
}
.projectDetailsBannerRight {
  display: flex;
  flex: 5;
  position: relative;
}

.projectDetailsBannerLeftDetails {
  margin-top: 40px;
}
h1 {
  margin-bottom: 40px;
}

.blueSquare {
  position: absolute;
  color: blue;
  right: -39%;
  z-index: 10;
  top: 40%;
}
.projectImgRight {
  position: absolute;
  z-index: 100;
  right: -9%;
  height: 700px;
  max-width: 630px;
  object-fit: contain;
}

@media (max-width: 400px) {
  .removeMobile {
    display: none;
  }
}
