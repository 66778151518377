.form {
  max-width: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 9;
}

.form img {
  width: 35px;
  height: 35px;
  max-width: unset;
}

.form-text{
  width: 100%;
  font-size: 14px;
  color: rgb(0, 169, 252);
  margin-left: 20px;
}

.form__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.form-inputs{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transition: z-index 0.3s, opacity 0.3s;
}



.swiping-img-en{
  position: absolute;
  left: 25px;
  width: 29px;
  height: 28px;
  cursor: pointer;
}

.swiping-img-de{
  width: 29px;
  height: 28px;
  cursor: pointer;
}

.front {
  z-index: 1; 
  opacity: 1; 
}

.back {
  z-index: 0.5; 
  opacity: 0.5; 
}

.form-inputs-images{
  display: flex;
}



@media(max-width: 1000px){
  .form{
    margin-bottom: 45px;
  }
}

/* .form__container img {
  width: 75px;
  max-width: unset;
} */

/* .swiping-img {
  position: absolute;
  width: 29px;
  height: 28px;
  z-index: 10;
  left: 2px;
  top: 3px;
  transform: scale(0.9);
  cursor: pointer;
} */
/* .swiping-img.swipe-left {
  animation: toggleLeft 500ms 1;
  animation-fill-mode: forwards;
  transition: 500ms ease all 500ms;
}

.swiping-img.swipe-right {
  animation: toggleRight 500ms 1;
  animation-fill-mode: forwards;
  transition: 500ms ease all 500ms;
} */

/* @keyframes toggleRight {
  0% {
    left: 42px;
    top: 3px;
    transform: scale(0.9);
  }
  20% {
    left: 37px;
    top: 3px;
    transform: scale(0.8);
  }
  40% {
    left: 20px;
    top: 3px;
    transform: scale(0.7);
  }
  50% {
    left: 8px;
    top: 3px;
    transform: scale(0.6);
  }
  75% {
    left: 5px;
    top: 3px;
    transform: scale(0.7);
  }
  100% {
    left: 2px;
    top: 2px;
    transform: scale(0.9);
  }
}

@keyframes toggleLeft {
  0% {
    left: 2px;
    top: 3px;
    transform: scale(0.9);
  }
  20% {
    left: 5px;
    top: 3px;
    transform: scale(0.8);
  }
  40% {
    left: 8px;
    top: 3px;
    transform: scale(0.7);
  }
  50% {
    left: 20px;
    top: 3px;
    transform: scale(0.6);
  }
  75% {
    left: 37px;
    top: 3px;
    transform: scale(0.7);
  }
  100% {
    left: 44px;
    top: 2px;
    transform: scale(0.9);
  }
} */

/* .left {
  border-radius: 50%;
  background-color: #00a9ff;
  width: 37px;
  height: 37px;
  border: 7px solid #00a9ff;
  border-radius: 50px 50px 0 50px;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .left img {
  z-index: 2;
}
.middle {
  margin-left: -10px;
  width: 40px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  background-color: #00a9ff;
}
.middle div {
  width: 19px;
  height: 19px;
  background-color: white;
  border-radius: 50%;
  margin-left: 4px;
}
.right {
  border-radius: 50%;
  background-color: #00a9ff;
  width: 37px;
  height: 37px;
  border: 7px solid #00a9ff;
  border-radius: 50px 50px 0 50px;
  transform: rotate(138deg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -27px;
} */
/* @media only screen and (max-width: 500px) {
  .form {
    margin-left: 20px;
  }
} */
