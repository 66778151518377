@font-face {
  font-family: black-font;
  src: url('./../fonts/Lato-Black.ttf');
}

@font-face {
  font-family: book-font;
  src: url('./../fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: bold-font;
  src: url('./../fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: regular-font;
  src: url('./../fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: thin-font;
  src: url('./../fonts/Lato-Thin.ttf');
}

@font-face {
  font-family: ligh-font;
  src: url('./../fonts/Lato-Light.ttf');
}

* {
  box-sizing: border-box;
  outline: 0;
  font-family: regular-font;
  padding: 0px;
  margin: 0px;
}

html {
  overflow-x: hidden !important;
}

body.nav-active #root {
  height: 100%;
  overflow: hidden;
  /* position: fixed; */
}

body {
  position: relative;
  width: 100%;
  height: 100vh;
  float: left;
}

body.nav-active {
  overflow: hidden;
  position: fixed;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
  padding: 0;
}

p::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

h1::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

h2::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

h3::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

h4::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

h5::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

h6::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

span::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

pre::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

a::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

.tags::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

time::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

img::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

button::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

input::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

textarea::selection {
  background-color: #00a9ff !important;
  color: white !important;
}

a,
a:focus,
a:after,
a:before,
a:visited,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

/* Generalization of Structure */

body {
  position: relative;
  overflow-x: hidden;
  float: left;
  width: 100%;
  height: 100%;
}

.content {
  overflow-x: hidden;
}

.first-container {
  padding: 0px;
  position: relative;
  height: inherit;
}

.second-container {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  height: inherit;
}

.second-container.extra-container {
  max-width: 1440px;
}

.container-no-limit {
  width: 100%;
  max-width: 100%;
  height: inherit;
}

.container-color {
  background-color: #f5f3f7;
}

/* .container{
    width: 100%;
    position: relative;
} */

.pright15 {
  padding-right: 15px;
}

.pleft15 {
  padding-left: 15px;
}

.grid {
  float: left;
  width: 100%;
  display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center;
  /* align-items: flex-start; */
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  -moz-align-content: flex-end;
  align-content: flex-start;
}

.grid .grid-1 {
  position: relative;
  float: left;
  width: 100%;
  transition: 0.3s linear all;
}

.grid .grid-2 {
  position: relative;
  float: left;
  width: calc(100% / 2);
  transition: 0.3s linear all;
}

.grid .grid-3 {
  position: relative;
  float: left;
  width: calc(100% / 3);
  transition: 0.3s linear all;
}

.grid .grid-4 {
  position: relative;
  float: left;
  width: calc(100% / 4);
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

.grid .grid-5 {
  position: relative;
  float: left;
  width: calc(100% / 5);
  transition: 0.3s linear all;
}

.grid .grid-6 {
  position: relative;
  float: left;
  width: calc(100% / 6);
  transition: 0.3s linear all;
}

@media only screen and (max-width: 1600px) {
  .grid .grid-5 {
    position: relative;
    float: left;
    width: calc(100% / 5);
    transition: 0.3s linear all;
  }
}

@media only screen and (max-width: 1300px) {
  .grid .grid-4 {
    position: relative;
    float: left;
    width: calc(100% / 4);
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
  }

  .project_box .left_part .project_title span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1150px) {
  .grid .grid-3 {
    position: relative;
    float: left;
    width: calc(100% / 3);
    /* margin-right: 15px;
        margin-bottom: 15px; */
    transition: 0.3s linear all;
  }

  .project_box .left_part .project_title span,
  .project_box .left_part .project_title span:first-child {
    font-size: 13px;
  }
}

@media only screen and (max-width: 960px) {
  .projects .projects-content {
    margin-top: 100px;
  }

  .grid .grid-2 {
    position: relative;
    float: left;
    width: calc(100% / 2);
    /* margin-right: 15px;
        margin-bottom: 15px; */
    transition: 0.3s linear all;
  }

  .insid_blog .inside_blog_ .insid_blog_first_ {
    height: 490px;
  }
}

@media only screen and (max-width: 600px) {
  .client_box .left-part {
    background-color: transparent;
  }

  .vh90 {
    height: 90vh !important;
  }

  .home-page {
    height: 90vh;
  }

  .right-part .quotes-left {
    bottom: 0;
  }

  .project-image-box-container {
    height: auto;
  }

  .inside-project .project-details-container h6 {
    padding-right: 0;
  }

  .project-image-box-container .big-box,
  .project-image-box-container .little-box {
    display: none;
  }

  .inside-project .project-image-box-container .item-image {
    margin-top: 30px;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
  }

  .text-editor.ql-editor.project-inside {
    padding-top: 0;
  }

  .inside-project .project-image-box-container .item-image img {
    max-width: 100%;
  }

  .about_leftpart span.description {
    font-size: 14px;
  }

  .bgImage {
    height: 90vh;
    background-position: center;
  }

  .tf_button.bottom {
    bottom: -60px;
  }
}

@media only screen and (max-width: 620px) {
  .home-page .home-page_text div .banner-btn {
    width: 215px;
    margin-left: 5px;
  }

  .home-page_text.break-point {
    display: flex;
    justify-content: center;
    text-align: start;
    align-items: flex-start;
    text-align: justify;
  }

  .home-page_text.break-point .mobile-text {
    font-size: 30px;
    display: block;
    line-height: 45px;
    /* text-align-last: right; */
  }

  .home-page_text.break-point .desktop-text {
    display: none;
  }

  .grid .grid-1 {
    position: relative;
    float: left;
    width: 100%;
    /* margin-right: 15px;
        margin-bottom: 15px; */
    transition: 0.3s linear all;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: inherit;
}

/* .container.extra-container {
  max-width: 900px;
} */

.career-banner-section-text p {
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media only screen and (min-width: 1500px) {
  .container {
    width: 1446px;
  }
}

/* tablet mode start */
@media only screen and (max-width: 1200px) {
  .process .process-desc .circle {
    padding: 30px;
  }

  /* .process .process-desc .grid .inside_grid:hover .circle {
    transform: scaleX(1) translate(-85%, -50%);
  } */
  .footer .footer-content .second-part .footer-content_.blog .blog-boxes {
    width: 140px;
    height: 140px;
  }

  .industry-section .parent-industry .right-part {
    min-height: 532px;
    display: flex;
    align-items: flex-end;
  }

  .industry-section .parent-industry .right-part .dots {
    left: 15%;
    transform: translateY(-38%);
  }

  .industry-section
    .parent-industry
    .left-part
    .inside-industry-boxes
    span.extra-span {
    bottom: 20px;
  }

  .process .process-desc .scale-boxes .description .title,
  .process .process-desc .square-boxes .inside_grid_ span:first-child {
    font-size: 17px;
    line-height: 25px;
  }

  .process .process-desc .scale-boxes .description .description_,
  .process .process-desc .square-boxes .inside_grid_ span:last-child {
    font-size: 13px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 1000px) {
  .projects .projects-content {
    margin-top: 100px;
  }

  #marginTopDivAbout {
    margin-top: 50px;
  }

  .process .process-desc .grid .inside_grid.scale,
  .process .process-desc .grid .inside_grid.square {
    opacity: 1;
  }

  .process .process-desc .grid .inside_grid:hover .square-boxes,
  .process .process-desc .grid .inside_grid:hover .scale-boxes {
    transform: unset;
    z-index: inherit;
  }

  /* .process .process-desc .grid .inside_grid:hover .circle {
    transform: translate(-50%, -50%);
    z-index: inherit;
  } */
  .process .process-desc .square-boxes .inside_grid_ span:first-child {
    font-size: 22px;
    line-height: 35px;
  }

  .process .process-desc .square-boxes .inside_grid_ span:last-child {
    font-size: 14px;
    line-height: 19px;
  }

  .process-desc .inside_grid {
    width: 100%;
  }

  .about_leftpart {
    width: 60%;
  }

  .about_rightpart {
    width: 40%;
  }

  .about_rightpart .about_rightpart_img:nth-child(2),
  .ldf .right-part {
    display: none;
  }

  .ldf .left-part,
  .industry-section .parent-industry .left-part,
  .about_rightpart .about_rightpart_img:first-child {
    width: 100%;
  }

  .industry-section .parent-industry .right-part,
  .footer .footer-content .second-part .footer-content_.blog {
    display: none;
  }

  .footer .footer-content .second-part {
    display: flex;
    justify-content: flex-end;
  }

  .footer .footer-content .second-part .footer-content_.contact {
    width: 80%;
  }

  .nav-menu .nav-menu_ .nav-menu__ {
    right: -50%;
    transition: 0.3s linear all;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #000047;
    height: 100vh;
    z-index: 999;
    padding-top: 90px;
    justify-content: unset;
    float: unset;
  }

  .nav-menu .nav-menu_ .nav-menu__.active {
    right: 0;
    width: 70%;
    position: fixed;
    z-index: 234253647586;
    background-image: url('/static/media/Bg.1dc754ce.svg') !important;
    background-position: 25% 100% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 234253647580;
  }

  .logo-mobile {
    display: block;
    position: absolute;
    top: 30px;
    left: 20px;
  }

  .logo-mobile img {
    object-fit: contain;
  }

  .social-nav {
    display: block;
    position: absolute;
    bottom: 40px;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside.language__ {
    position: absolute;
    bottom: 40px;
    right: 20px;
    margin: 0;
  }

  /* .nav-menu .nav-menu_ .nav-menu__ .showButton
    {
        display: inline;
        position: fixed;
        width: 30px;
        height: 30px;
        border: 1px solid #bbb;
        top: 35px;
        right: 40px;
        content: url('./../img/openColor.svg');
    }
    .nav-menu .nav-menu_ .nav-menu__.active .showButton
    {
        content: url('./../img/close.svg');
    } */
  .showButton {
    content: url('./../img/menuClosed.svg');
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 25px;
    z-index: 99999999;
    top: 35px;
  }

  .showButton.active {
    z-index: 10;
    width: 20px;
    height: 20px;
  }

  .showButtonClose.active {
    content: url('./../img/close.svg');
    right: 35px;
    position: absolute;
    top: 35px;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside {
    margin: 20px 20px;
    width: 100px;
    color: #fff;
    height: unset;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside a,
  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside span {
    color: #fff !important;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside:first-child {
    margin-top: 0px;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .mail-img,
  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ img {
    display: none;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside.extra .helper-div {
    display: inline;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside.extra {
    width: 100px;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ span {
    transform: unset;
    writing-mode: unset;
    width: auto;
    display: inherit;
  }

  .nav-menu .nav-menu_ .nav-menu__ .nav-menu__inside .helper-div_ {
    position: unset;
    width: auto;
    height: auto;
    top: unset;
  }

  .client_box .left-part {
    display: none;
  }

  .right-part .quotes-left {
    bottom: 0;
  }
}

@media only screen and (max-width: 850px) {
  .footer .footer-content .second-part .footer-content_.contact {
    width: 100%;
  }

  .project_box .right_part .project_images span {
    left: 10px;
  }
}

@media only screen and (max-width: 790px) {
  .owl-carousel.owl-loaded .owl-stage-outer .owl-stage .item {
    height: auto;
  }

  .project_box {
    flex-direction: column;
  }

  .project_box .left_part {
    display: none;
  }

  .project_box .right_part {
    width: 100%;
  }

  .project_box .little-boxes {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    display: inline;
    margin-top: 30px;
  }

  .project_box .little-boxes .little-boxes_:hover,
  .project_box .little-boxes .little-boxes_.active {
    background-color: #000047;
  }

  .project_box .little-boxes .little-boxes_ {
    position: relative;
    float: left;
    width: 15px;
    height: 15px;
    background-color: #979797;
    margin-right: 10px;
  }

  .tf_button.extra {
    bottom: 25px;
    right: 0 !important;
    color: #000047;
    left: unset;
  }

  .client_box .left-part .line {
    display: none;
  }

  .client_box .left-part {
    width: 30%;
    height: auto;
    flex-direction: row;
    margin-bottom: 30px;
  }

  .client_box .right-part {
    width: 100%;
  }

  .process .process-desc .grid .inside_grid:hover .title-process,
  .process .process-desc .grid .inside_grid:hover .title-process.square {
    transform: none;
  }
}

@media only screen and (max-width: 650px) {
  .open_position__ {
    padding: 15px 5px;
  }

  .open_position__ .open_position__right .open_position__right_top,
  .inside-project .open_position__right_bottom,
  .open_position__ .open_position__right .open_position__right_bottom {
    padding-left: 15px;
  }

  .inside-project .open_position__right_bottom_,
  .inside-project .open_position__right_bottom_ img,
  .open_position__ .open_position__right .open_position__right_bottom_,
  .open_position__ .open_position__right .open_position__right_bottom_ img {
    padding-right: 10px;
  }

  .inside-project .open_position__right_bottom_ span,
  .open_position__ .open_position__right .open_position__right_bottom_ span {
    font-size: 13px;
  }

  /* .open_position_:nth-child(even) {
    padding-left: 0;
  }
  .open_position_:nth-child(odd) {
    padding-right: 0;
  } */
  .form-apply .form-apply_ {
    max-width: 395px;
  }

  .about_leftpart {
    width: 100%;
  }

  .about_rightpart {
    display: none;
  }

  .footer .footer-content .second-part {
    width: 100%;
  }

  .mobileHide {
    display: none !important;
  }

  .mobileShow {
    display: inline;
  }

  .copyright.mobileShow {
    position: relative;
    float: left;
    width: 100%;
    color: #fff;
    top: 50px;
  }

  .home-page .home-page_text span {
    font-size: 40px;
  }

  .career-banner-section-text {
    border-bottom: none;
  }

  .career-banner-section-img img {
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 620px) {
  .career-banner-section-text {
    height: auto;
    padding-right: 30px;
    margin-right: 0;
  }

  .career-banner-section-img img {
    height: auto;
  }

  .inside_grid.pright15,
  .inside_grid.pleft15 {
    padding: 0;
  }

  .process-desc img.circle,
  .process-desc .square-boxes {
    display: none;
  }

  .process-desc img.agile {
    display: block;
    padding-right: 10px;
  }

  .process .process-desc .scale-boxes {
    height: auto;
  }

  .process .process-desc .scale-boxes .number {
    height: inherit;
  }

  .second-section.services .second-section_title {
    margin-top: 50px;
    flex-direction: column;
  }

  .second-section.services .second-section_title .btn-wrapper {
    margin-left: 0 !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 500px) {
  #secondSectionHome {
    padding-top: 40px;
    background: none;
  }

  .gridWidth {
    width: calc(100% / 2.1) !important;
  }

  #secondSectionHome .second-section-content-img {
    display: none;
  }

  #secondSectionHome .second-section-content {
    margin-bottom: 50px;
  }

  .second-section .second-section-content p {
    font-size: 16px;
  }

  .project-item-wrapper:last-child {
    margin-bottom: 45px;
  }

  .project-item {
    padding: 0;
    height: 250px;
  }

  .second-section {
    min-height: 300px;
  }

  .second-section .second-section_title {
    align-items: flex-start;
  }

  .second-section .btn-wrapper-boxe.boxes {
    display: flex;
    margin-bottom: 10px;
  }

  .second-section .btn-wrapper-boxe.boxes .inside_boxes {
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .second-section .btn-wrapper-mobile {
    margin-left: auto;
    height: 50px;
    margin-bottom: 10px;
  }

  .second-section {
    padding-top: 0;
  }

  .second-section .btn-wrapper-mobile a {
    border: 1px solid #707070;
    color: #000347;
    font-size: 18px;
    padding: 13px 45px;
    text-decoration: none;
  }

  .second-section.services .second-section_title .btn-wrapper {
    display: none;
  }

  .second-section_boxes {
    margin-top: 70px;
  }

  .second-section_boxes .boxes:nth-child(odd) {
    padding-left: 0;
    padding-right: 4px;
  }

  .second-section_boxes .boxes:nth-child(even) {
    padding-right: 0;
    padding-left: 4px;
  }

  .second-section_boxes .boxes .inside_boxes span {
    height: 150px;
    margin-bottom: 0;
    font-size: 16px;
  }

  .client_box .left-part {
    display: none;
  }

  .client_box {
    margin-bottom: 20px;
  }

  .client_box .quotes-left,
  .client_box .quotes-right {
    display: none;
  }

  .item .testimonial-item {
    margin: 30px 0 0 0;
  }

  .item .testimonial-item p {
    font-size: 16px;
  }

  .item .testimonial-item p.full-name {
    margin: 0;
  }

  .item .testimonial-item p:first-of-type,
  .item .testimonial-item p:nth-of-type(2) {
    margin-bottom: 0;
  }

  .owl-carousel .owl-nav button.owl-next {
    right: -10px;
  }

  .owl-carousel .owl-nav button.owl-prev {
    left: -10px;
  }

  .pleft15.right-part {
    padding-left: 0;
  }

  .projects-container .project-item-wrapper:nth-child(odd) {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .second-section .second-section-content p.sub_title,
  .second-section .second-section-content span.sub_title {
    margin-left: 0;
  }

  .second-section .second-section-content ._intro {
    padding-top: 10px;
  }

  .home-page_text.break-point {
    padding-top: 100px;
  }

  .home-page .social-home {
    display: none;
  }

  .home-page .scroll-arrow {
    display: none;
  }

  .home-page .home-page_text span {
    font-size: 30px;
  }

  .home-page .home-page_text span {
    font-size: 25px;
  }

  .nav-menu_ .nav-menu__ .nav-menu__inside span {
    font-size: 16px !important;
  }

  .nav-menu .nav-menu_ .nav-menu__ {
    width: 100%;
    right: -100%;
    padding-top: 80px;
  }

  .second-section .second-section_title span {
    font-size: 25px;
  }

  .home-page .social-home a:last-child,
  .home-page .social-home a:first-child {
    margin-right: 0px;
  }

  .lines-bg {
    display: none;
  }

  .projects.blogs {
    padding-top: 150px;
  }

  .projects.blogs .big-title.blog span {
    font-size: 20px;
    font-weight: bold;
  }

  .projects.blogs .inside_grid .inside_grid__ span {
    margin: 15px 0 40px 0;
  }

  .inside_blog-small .insid_blog_first_.img-part {
    height: 300px;
  }

  .pleft15.inside_small {
    padding-left: 0;
  }

  .blog .load-more {
    border: 1px solid #707070;
    color: #000347;
    font-size: 18px;
    padding: 13px 45px;
    text-decoration: none;
  }

  .career-banner-section-img {
    display: none;
  }

  .career-banner-section-text {
    border: none;
  }

  .career-banner-section-text h1 {
    margin-top: 20px;
    font-size: 20px;
    line-height: 35px;
  }

  .open_position {
    padding-top: 50px;
  }

  .inside_blog-small .insid_blog_first_.img-part img {
    padding-right: 0;
  }

  .blog .inside_blog-small .bottom-border {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .second-section .second-section_title span {
    font-size: 20px;
  }

  .second-section .second-section_title img {
    width: 30px;
  }

  .footer
    .footer-content
    .second-part
    .footer-content_.contact
    .contact-form
    .social.button {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  .footer
    .footer-content
    .second-part
    .footer-content_.contact
    .contact-form
    .social.button
    a {
    padding-top: 15px;
  }

  .footer
    .footer-content
    .second-part
    .footer-content_.contact
    .contact-form
    .social.button
    .social_ {
    width: 100%;
  }
}

/*  */
